import React from 'react';
import NoContent from './../GenericComponents/NoContent.tsx';
const apiUrl = process.env.REACT_APP_API_URL;

const DocumentsComponent = ({ detailsData, selectedRowIndex }) => {
  return (
    <>
      <div className='custom-header-1'>Technical Documents</div>
      {detailsData[selectedRowIndex]?.techfiles && detailsData[selectedRowIndex].techfiles.length > 0 ? (
        detailsData[selectedRowIndex].techfiles.map((doc, index) => (
          <table key={index} style={{ width: '100%', marginBottom: '10px' }}>
            <tbody>
              <tr className="detail-files">
                <td className="invoice-link" style={{ minWidth: '100%', fontSize: '12px' }}>
                  <a href={`${apiUrl}/api/download/techdoc/${doc.filetoken}`} download>
                    <i className="fas fa-file-pdf"></i>{doc.name}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        ))
      ) : (
        <div style={{ paddingRight: '16px' }}>
          <NoContent width='100%' height='100px' label='No technical documents' />
        </div>
      )}
      <div className='custom-header-1'>CAD Documents</div>
      {detailsData[selectedRowIndex]?.cadfiles && detailsData[selectedRowIndex].cadfiles.length > 0 ? (
        detailsData[selectedRowIndex].cadfiles.map((doc, index) => (
          <table key={index} style={{ width: '100%', marginBottom: '10px' }}>
            <tbody>
              <tr className="detail-files">
                <td className="invoice-link" style={{ minWidth: '100%', fontSize: '12px' }}>
                  <a href={`${apiUrl}/api/download/cadfile/${doc.filetoken}`} download>
                    <i className="fas fa-file-pdf"></i>{doc.name}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        ))
      ) : (
        <div style={{ paddingRight: '16px' }}>
          <NoContent width='100%' height='100px' label='No CAD files' />
        </div>
      )}
    </>
  );
};

export default DocumentsComponent;
