import React from 'react';

function MeasuringData({ inputArray }) {
    // Function to render a single measurement
    const renderMeasurement = (data) => {
        if (data && typeof data === 'object' && 
            data.hasOwnProperty('min') && 
            data.hasOwnProperty('max') && 
            data.hasOwnProperty('units')) {
            
            return (
                <div >
                    
                    <span >
                        <span className='module-span-1'>
                        <span className='module-span-2'>{`${data.min} - ${data.max} ${data.units}`}</span>
                        {data.accuracy && (
                            <span> ±{`${data.accuracy}`}%</span>
                        )}
                        </span>
                    </span>
                </div>
            );
        }

        // If data does not match the expected format,
        // Check if it's a simple type (string or number) and return it directly,
        // or return null if it's none of those.
        return typeof data === 'string' || typeof data === 'number' 
            ? <span>{data}</span> 
            : null;
    };

    // Check if inputArray is null or not an object
    if (!inputArray || typeof inputArray !== 'object') {
        return null;
    }

    // Iterate over the keys in the inputArray and render the measurements
    return (
        <div className='module-container'>
            <div style={{borderBottom: '1px #d8dee4 solid'}}>Part Measuring Ranges</div>
            {Object.keys(inputArray).map((key) => (
                <div key={key} >
                    {renderMeasurement(inputArray[key])}
                </div>
            ))}
        </div>
    );
}

export default MeasuringData;
