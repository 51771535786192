import React, { useState, useRef, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './css/SupplierEquipmentBody.css';
import FilterBar from './FilterBar.js';
import { useChipFilterContext } from './ChipComponents/context/ChipFilterContext.tsx';
import TabNavigation from './GenericComponents/TabNavigation.tsx';
import NoContent from './GenericComponents/NoContent.tsx';

import OperatingConditions from './EquipmentComponents/TableDisplay/operatingConditions.js'
import ProcessConnections from './EquipmentComponents/TableDisplay/processConnections.js';
import MeasuringData from './EquipmentComponents/TableDisplay/measuringData.js'
import MaterialsData from './EquipmentComponents/TableDisplay/materialsData.js'
import ElectricalData from './EquipmentComponents/TableDisplay/electricalData.js';

import NumberInputComponent from './GenericComponents/NumberInputComponent.tsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus, faMinus, faArrowDown, faCartShopping, faArrowRotateRight, faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import ProductCartButton from './EquipmentComponents/ProductCartButton.tsx';
import CloseButton from './GenericComponents/CloseButton.tsx';
import DocumentComponent from './EquipmentComponents/DocumentComponent.jsx';
import ConfigurationSelections from './ConfigurationSelections.jsx';
import ConfigurationOptions from './ConfigurationOptions.jsx';

const apiUrl = process.env.REACT_APP_API_URL;

function SupplierEntrypoint() {
  const [cookies] = useCookies(['cart_cookie', 'csrf_token']); 
  const { filterStates, prevFilterStates, setPrevFilterStates, partType, setPrevPartType, prevPartType } = useChipFilterContext();
  const [equipmentData, setEquipmentData] = useState([])
  const [dbOffset, setDbOffset] = useState(0)
  const [dbEnd, setDbEnd] = useState(false)
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [detailsData, setDetailsData] = useState({})
  //const [partType, setPartType] = useState('pressuretransducers')
  const [images, setImages] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [isPanelActive, setPanelActive] = useState(false)
  const [resetTriggered, setResetTriggered] = useState(false);

  useEffect(() => {
    console.log(detailsData)
    
  },[detailsData])

  useEffect(() => {
    setCurrentTabIndex(0);
  },[selectedRowIndex])

  const resetData = async () => {
    setSelectedRowIndex(null)
    setPanelActive(false);
    setDbOffset(0);
    setEquipmentData([]);
    setPrevPartType(partType);
    setPrevFilterStates(filterStates);
    setResetTriggered(true);
  };

  useEffect(() => {
    console.log(prevPartType)
  },[prevPartType])
  
  useEffect(() => {
    if (resetTriggered) {
      fetchData();
      setResetTriggered(false); // Reset the trigger after fetching
    }
  }, [resetTriggered]); // Run effect after states are updated
  

  const fetchData = async () => {
    console.log(dbOffset, equipmentData, filterStates)
    const isFiltering = Object.values(filterStates).some(value => value !== "");
    console.log(isFiltering)
    try {
      const endpoint = `${apiUrl}/api/get-equipment`
      const payload = {
        part_type: partType,
        offset: dbOffset,
        filtering: isFiltering
      };
      Object.entries(filterStates).forEach(([key, value]) => {
        if (value) payload[key] = value;
      });
      console.log('filter states',filterStates)
      console.log('si filtering?', isFiltering)
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrf_token, 
         },
        body: JSON.stringify(payload),
        credentials: 'include',
      };
      const response = await fetch(endpoint, requestOptions);
      const newData = await response.json();
      console.log(newData)
      setEquipmentData(prevData => [...prevData, ...newData.table_data]);
      setDbOffset(newData.currentDatabaseOffset)
      setDbEnd(newData.dbEnd) 
    } catch (error) {
      console.error('Error has occured', error)
    }
  } 

  const fetchRowDetails = async (index, supplierPartNumber, supplier) => {
    const url = `${apiUrl}/api/details`;  // Assuming details endpoint
    const payload = { partNumber: supplierPartNumber, supplier: supplier };
    
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrf_token,
        },
        body: JSON.stringify(payload),
        credentials: 'include'
      });
      const data = await response.json();
      setDetailsData(prev => ({ 
        ...prev, 
        [index]: {
          ...data,
          supplierpartnumber: supplierPartNumber
        } 
      }));
    } catch (error) {
      console.error("Failed to fetch details", error);
    }
  };

  const addImages = (newImages) => {
    setImages((prevImages) => ({
      ...prevImages,
      ...newImages,
    }));
  };

  const fetchImageForPart = async (imageName) => {
    console.log(imageName)
    if (!imageName || images[imageName]) return;

    try {
      const response = await fetch(`${apiUrl}/api/get-images`, {  // Assuming a different endpoint for a single image
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrf_token,
        },
        body: JSON.stringify({ image_names: [ imageName ] }),
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data)
      addImages({ [imageName]: data[imageName] }); // Update state with the specific image
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  const selectEquipmentRow = async (index) => {
    setSelectedRowIndex(index)
    setActiveTab(0);

    const windowWidth = window.innerWidth
    if (windowWidth <= 490) {
      console.log("shift gear")
      setPanelActive(true);
    }


    const selectedPart = equipmentData[index]
    await fetchRowDetails(index, equipmentData[index].supplierpartnumber, equipmentData[index].supplier);
    if (selectedPart.imagename) {
      await fetchImageForPart(selectedPart.imagename); // Fetch only the specific image for the selected part
    }
  }

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 490) {
        setPanelActive(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const selectedStyles = {
    color: '#339AF0',
    fontWeight: 'bold',
    display: 'inline-block'
  }

  const handleTabClick = (index) => {
    console.log(`Tab clicked: ${index}`);
    setCurrentTabIndex(index)
    // Perform additional actions based on the tab index
  };


  const didFetchData = useRef(false);
  useEffect(() => {
    // Ensure fetchData runs only once on component load
    if (!didFetchData.current) {
      fetchData();
      didFetchData.current = true; // Set the ref to true to prevent further calls
    }
  }, []);

  const handleLoadMore = () => {
    console.log("clicked the load more button!!!")
    fetchData()    
  }

  const handleCloseButtonClick = () => {
    setPanelActive(false); // Set the panel state to inactive
  };


  // config section //


  const [selectedOption, setSelectedOption] = useState({});
  const [selectedButton, setSelectedButton] = useState(0);
  const [, setSelectedOptions] = useState({});
  const [, setDisabledOptions] = useState({});
  const [nestedHashmap, setNestedHashmap] = useState({});
  const [activeConfigIndex, setActiveConfigIndex] = useState(0);

  useEffect(() => {
    setSelectedOption({})
    setSelectedButton(0)
    setActiveConfigIndex(0) //maybe?

    const buildHashmap = (data) => {
      const map = {};
      data.forEach(item => {
        const key = item.designation;
        map[key] = {};
        item.option.forEach(option => {
          map[key][option.designation] = option;
        });
      });
      return map;
    };
    console.log(equipmentData[selectedRowIndex])
    if ((equipmentData[selectedRowIndex] !== null) && (equipmentData[selectedRowIndex] !== undefined)) {
      const hashmap = buildHashmap(equipmentData[selectedRowIndex].configurableoptions);
      setNestedHashmap(hashmap);
    }
    
  }, [equipmentData[selectedRowIndex]]);

  const isOptionDisabled = (designation, optionDesignation) => {
    const disabledBy = nestedHashmap[designation]?.[optionDesignation]?.["disabledBy"];     
    if (!disabledBy) {
      return false
    }
    for (const [key, value] of Object.entries(disabledBy)) {
      if (Array.isArray(value)) {
        if (value.includes(selectedOption[key])) {
          return true;
        }
      } else {
        if (selectedOption[key] === value) {
          return true;
        }
      }
    }
    return false;
  };

  const handleOptionChange = (designation, optionDesignation) => {
    const updatedOptions = { ...selectedOption, [designation]: optionDesignation };

    // Sort the keys of updatedOptions
    const sortedKeys = Object.keys(updatedOptions).sort((a, b) => a.localeCompare(b, undefined, {numeric: true}));

    // Reconstruct the newSelectedOptions object with sorted keys
    const newSelectedOptions = sortedKeys.reduce((obj, key) => {
      obj[key] = updatedOptions[key];
      return obj;
    }, {});

    checkOptionState(designation, optionDesignation, newSelectedOptions)
    setSelectedOption(newSelectedOptions);
    // Auto-progress to the next set of options
    


    if (activeConfigIndex < equipmentData[selectedRowIndex].configurableoptions.length - 1) {
      if (isNarrow) {
        setActiveConfigIndex((prevIndex) => prevIndex);
        setSelectedButton((prevIndex) => prevIndex);
        setShowSelections(true)
      } else {
        setActiveConfigIndex((prevIndex) => prevIndex + 1);
        setSelectedButton((prevIndex) => prevIndex + 1);
      }
    } else {
      setSelectedButton(activeConfigIndex);
      if (isNarrow) {
        setShowSelections(true)
      }
    }
  };

  const handleButtonClick = (designation, optionDesignation, index) => {
    setActiveConfigIndex(index);
    setSelectedButton(index);
    if (isNarrow) { 
      setShowSelections(false)
    }
  };
  
  const checkOptionState = (designation, optionDesignation, newSelectedOptions) => {
    for (const [key, value] of Object.entries(newSelectedOptions)) {
      const disabledBy = nestedHashmap[key]?.[value]?.["disabledBy"]; 
      if (disabledBy !== undefined) {
        const isMatch = (subset, superset) => {
          for (let subKey in subset) {
            const subsetValue = subset[subKey];
            const supersetValue = superset[subKey];    
            if (Array.isArray(subsetValue)) {
              if (supersetValue !== undefined && subsetValue.includes(supersetValue)) {
                return true;
              }
            } else {
              if (subsetValue === supersetValue) {
                return true;
              }
            }
          }
          return false;
        };
  
        if (isMatch(disabledBy, newSelectedOptions)) {
          delete newSelectedOptions[key];
        }
      }
    }
  }   

  const containerRef = useRef(null);
  const [isNarrow, setIsNarrow] = useState(false);
  const [showSelections, setShowSelections] = useState(true);

  useEffect(() => {
    console.log("observing?")
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect.width < 400) {
          console.log("we be less than 400")
          setIsNarrow(true);
        } else {
          console.log("we be plunderin")
          setIsNarrow(false);
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [currentTabIndex]);




  return (
    <div className="supplier-body-entry-container">
        <FilterBar
          triggerSearch={resetData}
        />
        <div className="supplier-equipment-body">
            <div className={`supplier-equipment-left-panel ${isPanelActive ? 'active' : ''}`}>
                <div className="equipment-table-overflow-container">
                  <div className="equipment-table-positioning-container">
                    <table>
                      <tbody className='equipment-table-body'>
                        {equipmentData.map((item, index) => (
                          <tr 
                            key={index} 
                            style={{
                              borderBottom: selectedRowIndex === index ? '1px solid transparent' : '1px solid #d8dee4'
                            }}
                          >
                            <td 
                              onClick={() => selectEquipmentRow(index)}
                              style={{
                                border: selectedRowIndex === index ? '1px solid #339AF0': '', // Apply border if row is selected
                                //...(selectedRowIndex === index ? noHoverStyle : {}),
                              }}
                              className={selectedRowIndex === index ? 'no-hover-style' : ''}
                            >
                              <span className='equipment-table-span-1'>
                                <span 
                                  className='equipment-table-span-2 monostyle-text'
                                  style={
                                    selectedRowIndex === index ? selectedStyles : {}
                                  }>
                                    {item.supplierpartnumber}
                                </span>
                                <span className='equipment-table-span-3'>{item.supplier}{" "}{item.partname}</span>
                              </span>
                              <span className='equipment-table-span-4'>${item.price}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {equipmentData.length > 0 && !dbEnd  && (
                      JSON.stringify(prevFilterStates) === JSON.stringify(filterStates) && 
                      JSON.stringify(prevPartType) === JSON.stringify(partType) 
                      ? (
                        <div className='load-more-button' onClick={() => handleLoadMore()}>
                          <span><FontAwesomeIcon icon={faArrowDown} color='#339AF0' /></span>
                          <span>Show more</span>
                        </div>
                      ) : (
                        <div className='load-more-button' onClick={() => resetData()}>
                          <span><FontAwesomeIcon icon={faArrowRotateRight} color='#339AF0' /></span>
                          <span>Reload</span>
                        </div>
                      )
                    )}
                    {equipmentData.length === 0 && dbEnd && (
                      <div className='load-more-button' style={{pointerEvents: 'none'}}>
                        <span><FontAwesomeIcon icon={faLinkSlash} color='#339AF0' /></span>
                      <span>No parts found</span>
                    </div>
                    )}
                    
                  </div>
                </div>
            </div>
            <div className={`supplier-equipment-right-panel ${isPanelActive ? 'active' : ''}`}>
              {selectedRowIndex !== null ? (
                <>
                  <div className="supplier-equipment-right-panel-overflow-container">
                    <div className="right-panel-positioning-container">
                      <div className='product-cart-container'>
                        <ProductCartButton
                          selectedRowIndex={selectedRowIndex}
                          equipmentData={equipmentData}
                          selectedOption={selectedOption}
                          partType={partType}
                        />
                        <div className='product-description-container'>
                          <span style={{display: 'flex', flexDirection: 'column'}}>
                          <span className='right-panel-span-1'>Part details: {equipmentData[selectedRowIndex].supplierpartnumber}</span>
                          <span className='custom-header-2'>Product Description</span>
                          </span>
                          {isPanelActive && (
                          <div style={{marginLeft: 'auto', paddingRight: '16px'}}>
                            <CloseButton
                              onClick={handleCloseButtonClick}
                            />
                          </div>
                        )}
                        </div>
                      </div>  
                      <div className='part-description-container'>
                        <div className='part-image-container'>
                          {images[equipmentData[selectedRowIndex].imagename] ? (
                            <img
                              src={`data:image/jpeg;base64,${images[equipmentData[selectedRowIndex].imagename]}`}
                              alt="Product"
                              style={{ maxHeight: '100%', maxWidth: '100%', border: '1px solid #d8dee4' }}
                            />
                            ) : (
                              <NoContent width='100px' height='100px' label='No image'/>
                          )}
                        </div>
                        {detailsData[selectedRowIndex]?.description ? (
                          <span style={{ wordWrap: 'break-word', overflowWrap: 'break-word', margin: 0, textAlign: 'left' }}>
                            {detailsData[selectedRowIndex].description}
                          </span>
                        ) : (
                            <div style={{display: 'flex'}}>
                              <NoContent width='100%' height='100px' label='No description'/>
                            </div>
                        )}
                      </div>
                      <TabNavigation
                        tabs={equipmentData[selectedRowIndex]['configurableoptions'] ? ['Details', 'Documents', 'Configuration'] : ['Details', 'Documents']}
                        onTabClick={handleTabClick}
                        selectedRowIndex={selectedRowIndex}
                      />
                      {currentTabIndex === 0 && (
                        <>
                        <OperatingConditions inputArray={equipmentData[selectedRowIndex]['operatingconditions']} />
                        <ProcessConnections connections={equipmentData[selectedRowIndex]['connectiondata']} />
                        <ElectricalData inputArray={equipmentData[selectedRowIndex]['electricaldata']} />
                        <MeasuringData inputArray={equipmentData[selectedRowIndex]['measuringdata']} />
                        <MaterialsData inputArray={equipmentData[selectedRowIndex]['moc']} />
                        </>
                      )}
                      {currentTabIndex === 1 && (
                        <>
                        <DocumentComponent detailsData={detailsData} selectedRowIndex={selectedRowIndex}/>
                        </>
                      )} 
                      {currentTabIndex === 2 && equipmentData[selectedRowIndex]['configurableoptions'] && (
                        <>
                          <div className="configuration-table-container" ref={containerRef}>
                            <div className="configuration-header"></div>
                            <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                              {isNarrow ? (
                                <div className="config-container">
                                <div
                                  className="config-selections"
                                  style={{
                                    transform: showSelections ? "translateX(0)" : "translateX(-100%)",
                                  }}
                                >
                                  <ConfigurationSelections
                                    equipmentData={equipmentData}
                                    selectedRowIndex={selectedRowIndex}
                                    selectedButton={selectedButton}
                                    selectedOption={selectedOption}
                                    handleButtonClick={handleButtonClick}
                                    isNarrow={isNarrow}
                                    showSelections={showSelections}
                                  />
                                </div>
                                <div
                                  className="config-options"
                                  style={{
                                    transform: showSelections ? "translateX(0)" : "translateX(-100%)",
                                  }}
                                >
                                  <ConfigurationOptions
                                    equipmentData={equipmentData}
                                    selectedRowIndex={selectedRowIndex}
                                    activeConfigIndex={activeConfigIndex}
                                    isOptionDisabled={isOptionDisabled}
                                    handleOptionChange={handleOptionChange}
                                    selectedOption={selectedOption}
                                    isNarrow={isNarrow}
                                    showSelections={showSelections}
                                  />
                                </div>
                              </div>
                              ) : (
                                <>
                                
                                <ConfigurationSelections
                                  equipmentData={equipmentData} 
                                  selectedRowIndex={selectedRowIndex} 
                                  selectedButton={selectedButton} 
                                  selectedOption={selectedOption}
                                  handleButtonClick={handleButtonClick} 
                                  isNarrow={isNarrow}
                                />
                                <ConfigurationOptions
                                  equipmentData={equipmentData}
                                  selectedRowIndex={selectedRowIndex}
                                  activeConfigIndex={activeConfigIndex}
                                  isOptionDisabled={isOptionDisabled}
                                  handleOptionChange={handleOptionChange}
                                  selectedOption={selectedOption}
                                  isNarrow={isNarrow}
                                />
                            
                              </>
                              )}
                              
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className='nocontent-positioning-container'>
                  <NoContent width='100%' height='100%' label='Please select a part' />
                </div>  
                
              )}
              
            </div>
        </div>
    </div>
  );
}

export default SupplierEntrypoint;




//<div style={{marginLeft: 'auto', fontSize: '12px', paddingRight: '10px'}}> 
//                        <NumberInputComponent
//                          Quantity={cartQuantity}
//                          handleQuantityChange={(event) => handleCartQuantityChange(event)}
//                          incrementQuantity={() => handleIncrementQantity()}
//                          decrementQuantity={() => handleDecrementQuantity()}
//                        />
//                        </div>



//<span className="ml-auto" style={{display: 'flex', flexDirection: 'column', minWidth: '43px', textAlign:'left'}}>
//                                                        <span>+{option.productiontime} days</span>
//                                                        <span>+${option.price}</span>
//                                                      </span>