import React from 'react';

const ConfigurationOptions = ({
  equipmentData,
  selectedRowIndex,
  activeConfigIndex,
  isOptionDisabled,
  handleOptionChange,
  selectedOption,
  isNarrow,
  showSelections
}) => {
  return (
    <>
    <div className={`configuration-options`}>
      <div className="custom-header-1">Options</div>
      {equipmentData[selectedRowIndex].configurableoptions.map((item, index) => (
        <div key={index} style={{ paddingRight: isNarrow ? '16px' : '' }}>
          {activeConfigIndex === index && (
            <table>
              <tbody style={{ borderSpacing: '0px' }}>
                {item.option.map((option) => {
                  const isDisabled = isOptionDisabled(item.designation, option.designation);
                  return (
                    <tr key={option.designation}>
                      <td>
                        <div
                          style={{
                            marginBottom: '2px',
                            fontSize: '12px',
                            padding: '5px 8px',
                            border: 'solid 1px #ccc',
                            borderRadius: '4px',
                            backgroundColor: isDisabled ? '#f0f0f0' : 'white',
                            cursor: isDisabled ? 'default' : 'pointer',
                            opacity: isDisabled ? 0.5 : 1,
                          }}
                        >
                          <input
                            type="radio"
                            className="hidden-radio"
                            id={`${item.designation}-${option.designation}`}
                            name={item.designation}
                            value={option.designation}
                            disabled={isDisabled}
                            onClick={() =>
                              handleOptionChange(item.designation, option.designation, option.disabledBy)
                            }
                            checked={selectedOption[item.designation] === option.designation}
                          />
                          <label
                            htmlFor={`${item.designation}-${option.designation}`}
                            className="input-radio-label"
                            style={{ cursor: isDisabled ? 'default' : 'pointer' }}
                          >
                            <span
                              className="input-radio"
                              style={{ cursor: isDisabled ? 'default' : 'pointer' }}
                            ></span>
                            <span style={{ fontWeight: 'bold' }}>{option.designation}</span>
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'normal',
                                maxHeight: '64px',
                                width: '1px',
                                flex: 1,
                                display: '-webkit-box',
                                WebkitLineClamp: 4,
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {Array.isArray(option.description)
                                ? option.description.join(', ')
                                : option.description}
                            </span>
                          </label>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      ))}
      </div>
    </>
  );
};

export default ConfigurationOptions;
