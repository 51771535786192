import React, { useState, useEffect, useRef, useContext } from 'react';
import { useCookies } from 'react-cookie';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faXmark } from '@fortawesome/free-solid-svg-icons';
import NumberInput from './../GenericComponents/NumberInputComponent.tsx';
import { AlertContext } from '../../../customerBody/AlertContext.js';

type ProductCartButtonProps = {
  // Define any props that you might want to pass to this component
  selectedRowIndex: number;
  equipmentData: EquipmentData;
  selectedOption: Object;
  partType: String;
};

interface EquipmentData {
    currentDatabaseOffset: number;
    database_headers: string[];
    previousDatabaseOffset: number;
    dbEnd: boolean;
    success: boolean;
    table_data: TableData[]; // Define table_data as an array of TableData objects
  }

  interface TableData {
    configurableoptions: object[]; // Define the specific type if you have more details
    connectiondata: object; // Define the specific type if you have more details
    electricaldata: object; // Define the specific type if you have more details
    measuringdata: object; // Define the specific type if you have more details
    modcata: string[]; // An array of strings
    operatingconditions: object; // Define the specific type if you have more details
    partid: number;
    partname: string;
    price: number;
    quantity: number;
    supplier: string;
    supplierpartname: string;
    weight: number;
    // Add other fields as necessary
  }

const ProductCartButton: React.FC<ProductCartButtonProps> = ({selectedRowIndex, equipmentData, selectedOption, partType}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalPosition, setModalPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const cartButtonRef = useRef<HTMLDivElement | null>(null); // Update this to match the correct type for the ref
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [isTransitioning, setTransitioning] = useState<boolean>(false);
  const [isModalShifted, setModalShifted] = useState<boolean>(false);
  const [cartQuantity, setCartQuantity] = useState(1)
  const { showAlertMessage } = useContext(AlertContext)

  const item = equipmentData[selectedRowIndex];

  useEffect(() => {
    setCartQuantity(1);
  },[selectedRowIndex])

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  const handleCartButtonClick = () => {
    if (isModalOpen) {
      handleCloseModal();
    } else {
      console.log('clicked the modal open!');
      if (cartButtonRef.current) {
        const cartButtonRect = cartButtonRef.current.getBoundingClientRect();
        const newLeft = cartButtonRect.left;
        const windowWidth = window.innerWidth - 20;
        const totalModalLeft = newLeft + 300;
        setModalShifted((windowWidth < 430) || (windowWidth < totalModalLeft));

        setModalPosition({
            top: cartButtonRect.bottom + 8,
            left: newLeft
        })

        setModalOpen(true);
        setTransitioning(true);
        // Use `newLeft` as needed, or remove if unused
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (modalRef.current && cartButtonRef.current && isModalOpen) {

        
        const cartButtonRect = cartButtonRef.current.getBoundingClientRect();
        const newLeft = cartButtonRect.left;


        const windowWidth = window.innerWidth - 20;
        const totalModalLeft = newLeft + 300;
        setModalShifted((windowWidth < 430) || (windowWidth < totalModalLeft));

        
        setModalPosition(prevPosition => ({
          ...prevPosition,
          left: newLeft
        }));
      }
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[isModalOpen, modalRef])

  
  const handleCartQuantityChange = (e) => {
    setCartQuantity(e.target.value)
    console.log("changed!")
  }
  
  const handleIncrementQantity = () => {
    console.log("increment")
    let newQuantity = cartQuantity;
    const totalNewQuantity = newQuantity+1;
    setCartQuantity(totalNewQuantity);
  }
  
  const handleDecrementQuantity = () => {
    console.log("decrement");
    let newQuantity = cartQuantity;
    const totalNewQuantity = Math.max(newQuantity - 1, 1);
    setCartQuantity(totalNewQuantity);
  }

  const [cookies, setCookie] = useCookies(['cart_cookie']);


  const handleAddToCartButtonClick = () => {

    const selectedOptionLength = Object.keys(selectedOption).length
    console.log(selectedOptionLength)
    const configOptionsLength = Object.keys(equipmentData[selectedRowIndex].configurableoptions).length
    console.log(configOptionsLength)

    if (selectedOptionLength !== configOptionsLength) {
      console.log('config error')
      setTimeout(() => {
        showAlertMessage('warning', 'This item requires configuration!');
      }, 10)
      return
    }

    console.log("added to cart")
    const cartItem = {
      id: item.supplierpartnumber,
      company_name: item.supplier,
      part_type: partType,
      quantity: cartQuantity,
      imagename: item.imagename ? item.imagename : null,
      configurations: item.configurableoptions ? [{
          configuration: selectedOption,
          quantity: cartQuantity
      }] : []
    };

    let cartItems = [];

    if (cookies.cart_cookie) {
      try {
          if (typeof cookies.cart_cookie === 'object' && cookies.cart_cookie !== null) {
              cartItems = cookies.cart_cookie;
          } else {
              cartItems = JSON.parse(cookies.cart_cookie);
          }
      } catch (e) {
          console.error("Error parsing cart data: ", e);
          cartItems = [];
      }
    }

    const existingCartItemIndex = cartItems.findIndex(item =>
      item.id === cartItem.id &&
      item.company_name === cartItem.company_name &&
      item.part_type === cartItem.part_type
    );

    if (existingCartItemIndex >= 0) {
      const existingCartItem = cartItems[existingCartItemIndex];
      existingCartItem.quantity = parseInt(existingCartItem.quantity) + parseInt(cartQuantity);
      if (selectedOption) {
          //const totalQuantity = existingCartItem.configurations.reduce((sum, config) => sum + config.quantity, 0);
          const existingConfigIndex = existingCartItem.configurations.findIndex(config =>
              JSON.stringify(config.configuration) === JSON.stringify(selectedOption)
          );
          if (existingConfigIndex >= 0) {
              existingCartItem.configurations[existingConfigIndex].quantity += parseInt(cartQuantity);
          } else {
              existingCartItem.configurations.push({ configuration: selectedOption, quantity: parseInt(cartQuantity) });
          }
      }
    } else {
        cartItems.push(cartItem);
    }

    setCookie('cart_cookie', JSON.stringify(cartItems), {
      path: '/',
      expires: new Date(Date.now() + 259200000),
      secure: true,
      httpOnly: false,
      sameSite: 'lax' // Default is false, explicitly stated here for clarity
    });

    //do the success alert

    //close the cart modal

    setModalOpen(false)
    setTimeout(() => {
      showAlertMessage('success', 'Item/s added to cart!');
    }, 10)

  }


  return (
    <>
        <div
          className="product-cart-button"
          onClick={handleCartButtonClick}
          ref={cartButtonRef} // Corrected usage of ref
        >
            <FontAwesomeIcon icon={faCartShopping} color="#339af0" />
        </div>
        {isModalOpen && ReactDOM.createPortal(
            <div className={isModalShifted ? 'modal-overlay-2' : 'modal-overlay'}>
                <div 
                    className={`${isModalShifted ? 'modal-content-2' : 'modal-content'} ${isTransitioning ? 'show' : ''}`}
                    onClick={(e) => e.stopPropagation()}
                    style={{ top: modalPosition.top, left: modalPosition.left }}
                    ref={modalRef}
                >
                    <div className={`${isModalShifted ? 'modal-content-2-header-2' : 'custom-header-3'}`}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <span>Add to Cart</span>
                            
                            <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', padding: 0, marginLeft: 'auto' }}>
                              <FontAwesomeIcon
                                className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}
                                onClick={handleCloseModal}
                                style={{ display: 'flex', alignItems: 'center' }} />
                            </button>
                        </div>
                    </div>
                    <div >
                        <div 
                            style={{
                                display: 'Flex', 
                                borderBottom: '1px solid #d8dee4', 
                                padding: '8px 0px',
                                paddingRight: isModalShifted ? '16px' : '0px',
                                paddingLeft: isModalShifted ? '16px' : '0px'
                            }}>
                            <span className="equipment-table-span-1">
                                <span className="equipment-table-span-2 monostyle-text">{item.supplierpartnumber}</span>
                                <span className="equipment-table-span-3">
                                  {Object.values(selectedOption).join('-')}
                                </span>
                            </span>
                            <span className="equipment-table-span-4" style={{fontSize: '14px', display: 'flex', flexDirection: 'row', gap: '10px'}}>
                                <NumberInput
                                    Quantity={cartQuantity}
                                    handleQuantityChange={handleCartQuantityChange}
                                    incrementQuantity={handleIncrementQantity}
                                    decrementQuantity={handleDecrementQuantity}
                                    width='20px'
                                    height='20px'
                                />
                                <span className="general-center-style">
                                ${item.price}
                                </span>
                            </span>
                        </div>
                        <div style={{font:'arial', fontSize:'12px', marginTop: '5px', marginBottom: '5px'}}>
                            <span 
                                className="general-right-style" 
                                style={{
                                    paddingTop: '4px', 
                                    paddingBottom: '4px', 
                                    paddingRight: isModalShifted ? '16px' : '0px',
                                    paddingLeft: isModalShifted ? '16px' : '0px'
                                }}>
                                    <span 
                                        className="add-to-cart-button"
                                        onClick={handleAddToCartButtonClick}
                                    >
                                        Add {cartQuantity} to cart
                                    </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>,
          document.body
        )}
    </>
  );
};

export default ProductCartButton;
