import React from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'; // Import the IconDefinition type from FontAwesome

interface CloseButtonProps {
  icon?: IconDefinition; // Accepts any FontAwesome icon
  onClick: () => void; // Function for the click event
  className?: string; // Optional className for styling
  style?: React.CSSProperties; // Optional inline styles
}

const CloseButton: React.FC<CloseButtonProps> = ({
  icon = faXmark,
  onClick,
  className = 'h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500',
  style = { display: 'flex', alignItems: 'center' },
}) => {
  return (
    <FontAwesomeIcon
      className={className}
      icon={icon}
      onClick={onClick}
      style={style}
    />
  );
};

export default CloseButton;
