import React from 'react';

function ProcessConnections({ connections }) {
    console.log(connections)
    if (connections && typeof connections === 'object' && connections.hasOwnProperty('connection1')) {
        const connectionFields = ['type', 'size', 'units'];

        // Function to format the connection data
        const formatConnection = (connection) => {
            const formattedFields = connectionFields.map(field => {
                if (field === 'size' && Array.isArray(connection[field])) {
                    const sizes = connection[field];
                    if (sizes.length > 1) {
                        // Display as min(sizes) - max(sizes)
                        const sizeRange = `${Math.min(...sizes)} - ${Math.max(...sizes)}`;
                        return sizeRange;
                    } else if (sizes.length === 1) {
                        // Display single size
                        return sizes[0];
                    } else {
                        return ''; // No sizes to display
                    }
                }
                return connection[field];
            }).filter(val => val).join(' '); // Filter out null or undefined values and join the non-empty fields

            return formattedFields;
        };

        // Collect all connections in an array
        const connectionElements = [];

        // Process connection1 as it's guaranteed to be present
        connectionElements.push(
            <span key="connection1" className='module-span-1'>
                <span className='module-span-2'>Connection 1:</span>
                <span>{formatConnection(connections.connection1)}</span>
            </span>
        );

        // Check and format optional connections
        ['connection2', 'connection3', 'connection4'].forEach((conn, index) => {
            if (connections.hasOwnProperty(conn) && connections[conn].type !== null) {
                connectionElements.push(
                    <span key={conn} className='module-span-1'>
                        <span className='module-span-2'>Connection {index + 2}:</span>
                        <span>{formatConnection(connections[conn])}</span>
                    </span>
                );
            }
        });

        // Return the array of connection elements
        return (
            <div className='module-container'>
                <div style={{borderBottom: '1px #d8dee4 solid'}}>Process Connections</div>
                {connectionElements}
            </div>
        );
    }

    // If connections do not match the expected format, return null
    return null;
}

export default ProcessConnections;
