import React, { useEffect, useState, useContext, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { useFormData } from './context/FormDataContext.js';
import { useAuth } from '../AuthContext.js';
import { useShipmentData } from './context/ShipmentDataContext.js';
import { BreadCrumbContext } from './context/BreadCrumbContext';
import CheckoutForm from './CheckoutForm';
import { useMandate } from './context/PurchaseOrderMandateContext.js';
import { useCookies } from 'react-cookie';

const apiUrl = process.env.REACT_APP_API_URL;
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey);

function ConfirmationComponent() {
  const { user } = useAuth();
  const { purchaseOrderMandate, setPurchaseOrderMandate } = useMandate();
  const { formData, formData3 } = useFormData();
  const { shippingData } = useShipmentData();
  const { goToStep } = useContext(BreadCrumbContext);


  

  return (
    <div>
      
            
            <h2>Confirmed Details</h2>
            <div className="form-group">
                <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                    <div className="form-group location-group-confirm" style={{"width":"100%"}}>
                        <div className="form-group-wrapper-confirm">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <input style={{ backgroundColor: "#f0f0f0"}} type="text" id="email" placeholder=" " defaultValue={user.email} disabled></input>
                                <label htmlFor="email">Email Address</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                    <div className="form-group location-group-confirm" style={{"width":"100%"}}>
                        <div className="form-group-wrapper-confirm">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <input style={{ backgroundColor: "#f0f0f0"}} type="text" id="Delivery Address Details" placeholder=" " defaultValue={`${formData.location} ${formData.locality} ${formData.administrative_area_level_1} ${formData.postal_code} ${formData.country}`} disabled></input>
                                <label htmlFor="email">Delivery Address</label>
                                <button onClick={() => goToStep(1)} className="change-btn">Change</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                    <div className="form-group location-group-confirm" style={{"width":"100%"}}>
                        <div className="form-group-wrapper-confirm">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <input style={{ backgroundColor: "#f0f0f0"}} type="text" id="Billing Address Details" placeholder=" " defaultValue={`${formData3.location} ${formData3.locality} ${formData3.administrative_area_level_1} ${formData3.postal_code} ${formData3.country}`} disabled></input>
                                <label htmlFor="email">Billing Address</label>
                                <button onClick={() => goToStep(2)} className="change-btn">Change</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                    <div className="form-group location-group-confirm" style={{"width":"100%"}}>
                        <div className="form-group-wrapper-confirm">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <input style={{ backgroundColor: "#f0f0f0"}} type="text" id="Delivery Method Details" placeholder=" " defaultValue={`${shippingData.delivery_method}`} disabled></input>
                                <label htmlFor="email">Delivery Method</label>
                                <button onClick={() => goToStep(1)} className="change-btn">Change</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                    <div className="form-group location-group-confirm" style={{"width":"100%"}}>
                        <div className="form-group-wrapper-confirm">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <input style={{ backgroundColor: "#f0f0f0"}} type="text" id="Shipment Type Details" placeholder=" " defaultValue={`${shippingData.shipping_type}`} disabled></input>
                                <label htmlFor="email">Shipping Type</label>
                                <button onClick={() => goToStep(1)} className="change-btn">Change</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <div style={{ display: 'flex', flexDirection: 'column', border: 'solid 1px #ccc', marginTop: "10px" }}>
                <div style={{padding: "10px 8px"}}>
                    <input
                        id="purchase-order-mandate"
                        type="radio"
                        name="purchase-order-mandate"
                        className='hidden-radio'
                        value="Purchase Order Mandate"
                        checked={purchaseOrderMandate === true}
                        onChange={() => setPurchaseOrderMandate(!purchaseOrderMandate)}
                    />
                    <label htmlFor="purchase-order-mandate" className="input-radio-label">
                        <span className="input-radio" style={{"alignContent": 'center', "alignItems": 'center', "justifyContent": 'center'}}>
                        </span>
                        I certify that Zephyr Innovations Pty Ltd may create purchase orders on my behalf.
                    </label>
                </div>
            </div>
            
       

        </div>
        
    )
}


export default ConfirmationComponent



