import React, { useState, useEffect } from 'react';

type InputBoxComponentProps = {
  string: string | number;
  setString: (name: string) => void;
  label: string;
  regexType: string;
  defaultValue?: string | number;
  disabled?: boolean;
};

const InputBoxComponent: React.FC<InputBoxComponentProps> = ({ string, setString, label, regexType, defaultValue = '', disabled = false }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [, setHasError] = useState(false);

  const regexPatterns: { [key: string | number]: RegExp } = {
    number: /^[0-9]*$/,
    string: /^[A-Za-z0-9 ]*$/,
    address: /^[A-Za-z0-9 ,.-]*$/,
    abn: /^\d{11}$/,
    phone: /^0[4]\d{8}$/,
    email: /^[a-zA-Z0-9._%+-@]*$/
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    const regex = regexPatterns[regexType];
    console.log(value)
    // Check if value matches the regex pattern
    if (regex && regex.test(value)) {
      setString(value);
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (string) {
      setIsFocused(true);
      setHasError(!regexPatterns[regexType].test(String(string)));
    } else {
      setIsFocused(false);
    }
  }, [string, regexType]);

  const inputStyle: React.CSSProperties = {
    width: '100%',
    padding: isFocused || string ? '16px 5px 3px 5px' : '8px 5px',
    fontSize: '12px',
    transition: 'padding-top 0.2s ease, padding-bottom 0.2s ease',
    border: 'none',
    outline: 'none',
    borderRadius: '4px',
  };

  const labelStyle: React.CSSProperties = {
    position: 'absolute',
    top: isFocused || string ? '0px' : '8px',
    left: '5px',
    fontSize: isFocused || string ? '10px' : '12px',
    color: isFocused || string ? '#000' : '#aaa',
    pointerEvents: 'none',
    transition: 'all 0.2s ease',
  };

  const formGroupStyle: React.CSSProperties = {
    position: 'relative',
  };

  const formGroupWrapper: React.CSSProperties = {
    border: 'solid 1px #ccc',
    borderRadius: '4px',
    paddingBottom: isFocused || string ? '0px' : '3.5px',
    transition: 'all 0.2s ease',
    marginBottom: '5px',
    background: disabled ? '#eee' : '#fff', 
    width: '100%'
  };

  return (
    <div style={{display: 'flex', minHeight: '40.5px'}}>
    <div style={formGroupWrapper}>
      <div style={formGroupStyle}>
        <input
          style={inputStyle}
          type="text"
          id={label}
          name={label}
          placeholder=" "
          value={string}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => !string && setIsFocused(false)}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        <label htmlFor={label} style={labelStyle}>{label}</label>
      </div>
    </div>
    </div>
  );
};

export default InputBoxComponent;
