import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import InputWithUnitSelector from './../GenericComponents/ChipInputBoxWithSelectorComponent.tsx';
import InputBoxComponent from './../GenericComponents/ChipInputBoxComponent.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useEquipment } from '../../../customerBody/CombinedContext.js';
import './css/filterGroup.css';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useChipContext } from './context/ChipContext.js';
import { useChipFilterContext } from './context/ChipFilterContext.tsx';

interface DefaultDataFilterProps {
  defaultData: any;
  setDefaultData: (data: any) => void;
  //onFilteredLengthChange: (length: number) => void;
}

const defaultSampleOpData = {
  "operating_pressure": {"label": "Pressure", "selectors": null, "units": null },
};

const DefaultDataFilter: React.FC<DefaultDataFilterProps> = ({ 
  defaultData, 
  setDefaultData, 
  //onFilteredLengthChange,
  
}) => {
  
  const { chipFilters } = useChipContext();
  const { inputValue, setInputValue } = useChipFilterContext();
  const [sampleOpDataState] = useState(
    chipFilters['defaultdata']
  )
  const [loaded] = useState('true')

  

  const initializeValues = (data: typeof defaultSampleOpData, defaultData: any) => {
    const initialValues: { [key: string]: string | number } = {};
    Object.keys(data).forEach(key => {
      initialValues[key] = defaultData[key]?.size || '';
    });
    return initialValues;
  };

  const initializeUnitValues = (data: typeof defaultSampleOpData, defaultData: any) => {
    const initialUnitValues: { [key: string]: string | null } = {};
    Object.keys(data).forEach(key => {
      initialUnitValues[key] = defaultData[key]?.units || data[key].units || '';
    });
    return initialUnitValues;
  };
    
  const [selection, setSelection] = useState<string>(Object.keys(defaultSampleOpData)[0]);
  const [values, setValues] = useState<{ [key: string]: string | number }>(initializeValues(sampleOpDataState, defaultData));
  const [units, setUnits] = useState<{ [key: string]: string | null }>(initializeUnitValues(sampleOpDataState, defaultData));
  const [fullView, setFullView] = useState<boolean>(true);

  useEffect(() => {
    if (defaultData === 'reset') {
      resetState()
    }
  },[defaultData])

  const resetState = useCallback(() => {
    if (isMobileView) {
      setInputValue('')
    } 
    setValues(initializeValues(sampleOpDataState, {}));
    setUnits(initializeUnitValues(sampleOpDataState, {}));
    setDefaultData({});
  }, [sampleOpDataState, setDefaultData]);

  useEffect(() => {
    let updatedDefaultData: any = {};

    Object.keys(sampleOpDataState).forEach(key => {
      if (values[key] !== '') {
        updatedDefaultData[key] = { size: values[key], units: units[key] };
      }
    });

    if (Object.keys(updatedDefaultData).length === 0) {
      updatedDefaultData = "";
    }

    setDefaultData(updatedDefaultData);
  }, [values, units, sampleOpDataState, setDefaultData]);

  const handleSelectionChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelection(e.target.value);
  }, []);

  const handleValueChange = useCallback((key: string) => (value: string | number) => {
    setValues(prevValues => ({ ...prevValues, [key]: value }));
  }, []);

  const handleUnitChange = useCallback((key: string) => (unit: string | null) => {
    setUnits(prevUnits => ({ ...prevUnits, [key]: unit }));
  }, []);

  const renderInputComponent = useCallback((key: string) => {
    const selectedData = sampleOpDataState[key];
    if (selectedData.selectors) {
      return (
        <InputWithUnitSelector
          value={values[key] || ''}
          setValue={handleValueChange(key)}
          unit={units[key] || ''}
          setUnit={handleUnitChange(key)}
          label={selectedData.label}
          selectors={selectedData.selectors}
        />
      );
    } else {
      return (
        <InputBoxComponent
          string={values[key] || ''}
          setString={handleValueChange(key)}
          label={selectedData.label}
          regexType='string'
        />
      );
    }
  }, [sampleOpDataState, values, units, handleValueChange, handleUnitChange]);

  const toggleView = useCallback(() => {
    setFullView(prevFullView => !prevFullView);
  }, []);


  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 400);
  useEffect(() => {
    // Function to update the window width state
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 400);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {!sampleOpDataState || !loaded ? (
        <div>
          <div style={{ fontFamily: 'inherit', fontSize: '14px', marginBottom: '3px', display: "flex", flexDirection: 'row', paddingTop: '2px' }}>
            <div style={{width: "100%"}}>Default Data</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton height={20} width={20} />
            </div>
          </div>
          <div style={{marginBottom: '5px'}}>
            <Skeleton height={18} width={178} />
          </div>
          <div style={{paddingBottom: '5px', marginBottom: '5px'}}>
            <Skeleton height={34} width={178}/>
          </div>
        </div>
      ) : (
        <div>
          <div style={{  marginBottom: '5px', display: "flex", flexDirection: 'row' }}>
            
              <button onClick={toggleView} style={{border: 'none', background: 'transparent', cursor: 'pointer', padding: 0, fontFamily: 'inherit', fontSize: '14px',color: fullView ? '#339AF0':'black', fontWeight: fullView ? 'bold': ''}}>Part Details</button>
              <button 
                    className="clear-filters-button"
                    onClick={() => resetState()}
                    style={{marginLeft: 'auto', alignItems: 'right'}}
                >
                    <span style={{}}>Clear</span>
                </button>
            
            
          </div>
          <div>
            {fullView ? (
              <>
              {Object.keys(sampleOpDataState).map(key => {
                console.log(key); // Logs the key to the console
          
                return (
                  <div key={key}>
                    
                    {
                      renderInputComponent(key)
                    }
                  </div>
                  
                );
              })}
              {isMobileView && (
                      <InputBoxComponent
                        string={inputValue}
                        setString={setInputValue}
                        label={'Part Number'}
                        regexType='string'
                      />
                    )}
            </>
              
            ) : (
              <>
                <div>
                  <select
                    value={selection}
                    onChange={handleSelectionChange}
                    style={{ marginBottom: '5px', width: "100%", fontSize: '14px', border: '1px solid #ccc', borderRadius: '4px' }}
                  >
                    {Object.keys(sampleOpDataState).map(key => (
                      <option key={key} value={key}>{sampleOpDataState[key].label}</option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {renderInputComponent(selection)}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DefaultDataFilter;
