import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import './css/SupplierEquipmentBody.css';
import Chip from './ChipComponents/Chip.tsx';
import { useChipContext } from './ChipComponents/context/ChipContext.js';
import ChipHidden from './ChipComponents/ChipHidden.tsx';
import { useChipFilterContext } from './ChipComponents/context/ChipFilterContext.tsx';
import InputBoxComponent2 from './GenericComponents/ChipInputBoxComponent2.tsx';

function FilterBar({triggerSearch}) {
    //const [inputValue, setInputValue] = useState('');
    const [inputValue2, setInputValue2] = useState('')
    const [isModalOpen, setModalOpen] = useState(false);
    const [isModal2Open, setModal2Open] = useState(false);
    
    const modalRef = useRef(null);

    const { 
        fetchChipFilters, 
        chipFilters, 
        hiddenChips, 
        setHiddenChips, 
        showHiddenChip 
      } = useChipContext();
    
      const { filterStates, setFilterState, totalFilterStateLength, clearFilterStates, setPartType, partType, inputValue, setInputValue } = useChipFilterContext();
    
      const handleInputChange = (event) => {
        setInputValue(event.target.value);
        console.log(event.target.value)
        setFilterState('part_number_supplier', { ...filterStates['part_number_supplier'], input: event.target.value })
      };
    
      const handleRightIconClick = () => {
        console.log('Right icon clicked');
        triggerSearch()
        //this should search for items
      };
    
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          handleRightIconClick();
        }
      };
    
      const handleMoreFiltersClick = () => {
        setModalOpen(true);
        setModal2Open(false)
      };
    
      const handleModalClose = () => {
        setModalOpen(false);
        setModal2Open(false);
      };
    
      useEffect(() => {
        fetchChipFilters();
      }, []);
    
      const handleChipVisibilityChange = (type, isVisible) => {
        setHiddenChips((prev) =>
          isVisible ? prev.filter((chip) => chip !== type) : [...prev, type]
        );
      };
    
      const [filteredOptions, setFilteredOptions] = useState([]);
      const options = [
        { 'pressuretransducers': 'Pressure Transducers' },
        { 'pressureguages': 'Pressure Gauges'},
        { 'pressuresensors': 'Pressure Sensors'},
        { 'pressureswitches': 'Pressure Switches'},
        { 'temperaturetransducers': 'Temperature Transducers' },
        { 'temperaturesensors': 'Temperature Sensors'},
        { 'temperatureswitches': 'Temperature Switches'},
        { 'leveltransducers': 'Level Transducers'}
      ];
    
      const handlePartTypeClick = () => {
        setFilteredOptions(options)
        setModal2Open(true)
        setModalOpen(false)
      }
      
      const handleInput2Change = (value) => {
        setInputValue2(value);
    
        // Filter options based on input value
        const filtered = options.filter(option => {
          // Extract the single key-value pair from each object
          const [key, optionValue] = Object.entries(option)[0];
          return optionValue.toLowerCase().includes(value.toLowerCase());
        });
    
        setFilteredOptions(filtered);
      };
    
      function getValueByKey(key) {
        for (const option of options) {
          if (option[key]) {
            return option[key];
          }
        }
        return null; // Return null if the key is not found
      }
    
      const handleFilteredListClick = (key) => {
        console.log("clicked part with key:", key)
        setPartType(key)
        console.log(filterStates)
        
        setModal2Open(false)
        setInputValue2('')
      }
    
      const handleClickOutside = (event) => {
        // Check if the click was outside the modal content
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setModal2Open(false); // Close the modal
        }
      };
    
      useEffect(() => {
        // Add event listener when modal is open
        if (isModal2Open) {
          document.addEventListener('mousedown', handleClickOutside);
        }
    
        // Cleanup event listener on component unmount or when modal is closed
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [isModal2Open]);

    return (
        <>
          <div className="filter-bar-container">
            <div className="filter-bar">
              <div className="filter-bar-contents">
                <div className="hide-on-small-screen">
                  <input
                    className="filter-by-id-input"
                    placeholder="Find part by ID..."
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    width={12}
                    height={12}
                    id="magnifying-glass"
                  />
                  {inputValue && (
                    <FontAwesomeIcon
                      icon={faRightToBracket}
                      width={16}
                      height={16}
                      id="right-to-bracket"
                      onClick={handleRightIconClick}
                    />
                  )}
                </div>
                <div className="filter-chip-container">
                  {Object.entries(chipFilters).map(([filterType, filters], index) => {
                    return (
                        <Chip
                          key={filterType} // Ensure unique key for each chip
                          type={filterType}
                          filter={filters}
                          onVisibilityChange={handleChipVisibilityChange}
                          chipIndex={index}
                        />
                    )
                  })}
                  {showHiddenChip && (
                    <ChipHidden
                      type={'morefilters'}
                       //here is where the total filter state goes
                      hiddenChips={hiddenChips}
                      onClick={handleMoreFiltersClick}
                    />
                  )}
                  {totalFilterStateLength > 0 && (Object.keys(chipFilters).length !== hiddenChips.length) && (
                    <button 
                        className="clear-filters-button"
                        onClick={clearFilterStates}
                    >
                        <span style={{width: '72px'}}>Clear filters</span>
                    </button>
                  )}
                </div>
              </div>
              <button className="search-filters-button">
                <span style={{width: '72px'}} onClick={handleRightIconClick}>Search</span>
              </button>
            </div>
          </div>
          <div className="part-type-container">
              <button onClick={handlePartTypeClick} className="part-type-button-2">{getValueByKey(partType)}</button>
          </div>
          {isModalOpen && (
            <div className="modal-overlay" onClick={handleModalClose}>
              <div className="modal-content">
                {hiddenChips.map((chip) => (
                  <div key={chip}>{chip}</div>
                ))}
              </div>
            </div>
          )}
          {isModal2Open && (
            <div className="modal-overlay-3">
              <div className="modal-content-3" ref={modalRef}>
                <InputBoxComponent2
                  string={inputValue2}
                  setString={handleInput2Change}
                  label={'Search part types..'}
                  regexType='string'
                />
                <div className="filtered-list-container">
                  {filteredOptions.length > 0 && (
                    <ul>
                      {filteredOptions.map((option, index) => {
                        const [key, value] = Object.entries(option)[0];
                        return (
                          <li key={key} onClick={() => handleFilteredListClick(key)}>
                            <div>{value}</div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
    )



}


export default FilterBar




