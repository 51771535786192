import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faXmark } from '@fortawesome/free-solid-svg-icons';
import './css/Chip.css';
import ChipOperatingConditions from './ChipOperatingConditions.tsx';
import ChipMeasuringConditions from './ChipMeasuringConditions.tsx';
import ChipConnectionData from './ChipConnectionData.tsx';
import ChipDefaultData from './ChipDefaultData.tsx';
import { useEquipment } from '../../../customerBody/CombinedContext.js';
import { useChipFilterContext } from './context/ChipFilterContext.tsx';
import { useChipContext } from './context/ChipContext.js';

interface ChipHiddenProps {
  type: string;
  filter: object;
  hiddenChips: [string];
  style?: React.CSSProperties; // Add this to allow passing styles from parent
  onVisibilityChange?: (type: string, isVisible: boolean) => void;
}

const ChipHidden = React.forwardRef<HTMLSpanElement, ChipHiddenProps>(({ type, filter, hiddenChips, style, onVisibilityChange }, ref) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalPosition, setModalPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [isTransitioning, setTransitioning] = useState<boolean>(false);
  const chipRef = useRef<HTMLSpanElement | null>(null); // Internal reference for the chip
  const modalRef = useRef<HTMLDivElement | null>(null);

  //const [filterState, setFilterState] = useState(filter || {"connection1":{"size": '80', "type": '', "units": 'DN'}});
  const [filterStateLength, setFilterStateLength] = useState(0);
  const { filterStates, setFilterState, clearFilterStates } = useChipFilterContext();
  const { chipFilters } = useChipContext()
  
  //const currentFilterState = filterStates[type] || filter || {};

  const handleFilterStateChange = useCallback((newState: object) => {
    setFilterState(type, newState);
  }, [setFilterState, type]);
  
  const [isVisible, setIsVisible] = useState(true); 
  const [chipWidth, setChipWidth] = useState(0);
  const [chipLeft, setChipLeft] = useState(0);

  useEffect(() => {
    if (onVisibilityChange) {
      onVisibilityChange(type, isVisible);
    }
  }, [isVisible]);

  useEffect(() => {
    console.log("hidden chip filter states", filterStates);
    console.log("hidden chips (in hiddenChip)", hiddenChips);
    console.log("current type", type);
  
    // Initialize the total count for non-empty properties
    let totalFilteredLength = 0;
  
    const updateFilterState = () => {
      totalFilteredLength = 0;
  
      // Iterate over all keys in the parent filterStates object
      Object.entries(filterStates).forEach(([key, childState]) => {
        // Check if the key is in hiddenChips and the child state is an object
        if (hiddenChips.includes(key) && childState && typeof childState === 'object') {
          // Count the number of non-empty objects within the current child state
          const filteredLength = Object.values(childState).filter((value) => {
            // Check if the value is an object and has at least one non-empty, non-null property
            return (
              value &&
              typeof value === 'object' &&
              Object.values(value).some((innerValue) => innerValue !== '' && innerValue !== null)
            );
          }).length;
  
          // Add the filtered length of the current child state to the total count
          totalFilteredLength += filteredLength;
        }
      });
  
      // Check if window width is less than or equal to 400px
      if (window.innerWidth <= 400) {
        // Specifically check for part_number_supplier
        if (
          filterStates.part_number_supplier &&
          typeof filterStates.part_number_supplier === 'object' &&
          Object.values(filterStates.part_number_supplier).some(
            (value) => value !== '' && value !== null
          )
        ) {
          totalFilteredLength += 1; // Increment the total count
        }
      }
  
      // Set the total filtered length count
      setFilterStateLength(totalFilteredLength);
      console.log('Total Filtered Length for hidden chip:', totalFilteredLength);
    };
  
    // Call the update function on component mount
    updateFilterState();
  
    // Add an event listener for window resize
    const handleResize = () => {
      updateFilterState();
    };
    
    window.addEventListener('resize', handleResize);
  
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [filterStates, isVisible, onVisibilityChange, type, hiddenChips]);
  



  const labels = {
    operatingconditions: 'Operating Conditions',
    measuringdata: 'Measuring Data',
    connectiondata: 'Process Connections',
    morefilters: 'More Filters'
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target instanceof HTMLDivElement && e.target.classList.contains('modal-overlay')) {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      handleCloseModal();
    }
  };

  const handleChipClick = () => {
    console.log('chip clicked');
    if (isModalOpen) {
      handleCloseModal();
    } else {
      if (chipRef.current) {
        const chipRect = chipRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        console.log(chipRect.left + 75 + 150 + 20, windowWidth)
        const newLeft = chipRect.left + 75 + 150 + 20
        
        if (newLeft > windowWidth) {
          setModalPosition({
            top: chipRect.bottom + window.scrollY + 8,
            left: windowWidth - 300 - 20,
          });
        } else {
          setModalPosition({
            top: chipRect.bottom + window.scrollY + 8,
            left: chipRect.left + window.scrollX - 65,
          });
        }
        
      }
      setModalOpen(!isModalOpen);
      setTransitioning(true);
    }
  };

  useEffect(() => {
    if (chipRef.current) {
      const currentWidth = chipRef.current.offsetWidth + 20;
      const currentChipLeft = chipRef.current.getBoundingClientRect().left;
      setChipWidth(currentWidth);
      setChipLeft(currentChipLeft)
    }
  },[])

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);


  const setRefs = (node: HTMLSpanElement) => {
    chipRef.current = node; // Set internal ref
    if (typeof ref === 'function') {
      ref(node); // Call the forwarded ref if it's a function
    } else if (ref) {
      (ref as React.MutableRefObject<HTMLSpanElement | null>).current = node; // Set the forwarded ref
    }
  };

  const filtersStyle = {
    border: '1px solid #d8dee4'
  }

  const cogStyle = {
    color: "#339AF0"
  }

  // Forward the ref to the chip element
  return (
    <span aria-hidden="false" style={{ display: isVisible ? 'inline-flex' : 'none' }}>
      <span className="filter-chip" ref={setRefs} onClick={handleChipClick} style={filterStateLength > 0 ? filtersStyle : {}}>
        <FontAwesomeIcon icon={faGear} width={12} height={12} id="gear-icon" style={filterStateLength > 0 ? cogStyle : {}}/>
        {labels[type]}
        {filterStateLength > 0 && (
            <>
                {" | "}
                <span style={{color: '#339AF0', fontWeight: 'bold'}}>{filterStateLength}</span>
            </>
        )}
      </span>
      {isModalOpen && (
        <div className="modal-overlay-2" onClick={handleOverlayClick}>
          <div
            className={`modal-content-2 ${isTransitioning ? 'show' : ''}`}
            onClick={(e) => e.stopPropagation()}
            style={{ top: modalPosition.top, left: modalPosition.left }}
            ref={modalRef}
          >
            <div className='modal-content-2-header'>
              <span>Filters</span>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
              <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                
                <FontAwesomeIcon
                  className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}
                  onClick={() => handleCloseModal()}
                  style={{ display: 'flex', alignItems: 'center' }} />
              </button>
            </div>
            </div>
            <div className="modal-content-2-body-container">
            <div className='modal-content-2-body'>

            
            {hiddenChips.map((chipType) => {
              const chipFilterState = filterStates[chipType] || {};

              return chipType === 'operatingconditions' ? (
                <ChipOperatingConditions
                  key="operatingconditions"
                  operatingConditionsData={chipFilterState}
                  setOperatingConditionsData={(newState) => setFilterState(chipType, newState)}                  
                />
              ) : chipType === 'measuringdata' ? (
                <ChipMeasuringConditions 
                  key="measuringdata" 
                  measuringRangeData={chipFilterState}
                  setMeasuringRangeData={(newState) => setFilterState(chipType, newState)}
                />
              ) : chipType === 'connectiondata' ? (
                <ChipConnectionData
                  key="connectiondata"
                  connectionData={chipFilterState}
                  setConnectionData={(newState) => setFilterState(chipType, newState)}
                />
              ) : chipType === 'defaultdata' ? (
                <ChipDefaultData
                  defaultData={chipFilterState}
                  setDefaultData={(newState) => setFilterState(chipType, newState)}
                />
              
              ) : null;
            })}
            </div>






            </div>
            
          </div>
        </div>
      )}

    </span>
  );
});

export default ChipHidden;
