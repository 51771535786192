import { PaymentElement } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useStripeContext } from './context/StripeContext';

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  
  const { handleOrderSubmit, handleSetStripeElements } = useStripeContext()

  

  useEffect(() => {
    if (!stripe || !elements) {
        return;
    }  
    handleSetStripeElements(stripe, elements)
    const cardNumberElement = elements.create('payment', {
        fields: {
            billingDetails: {
                name: 'never',
                email: 'never',
              }
        }
    }
    );
    cardNumberElement.mount('#card-number-element-container');

  },[stripe, elements])

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <form id="payment-form" onSubmit={handleOrderSubmit}>
      {/* Show any error or success messages */}
      <div className="form-group">
          <div id="card-number-element-container"></div>
      </div>
    </form>
  );
}