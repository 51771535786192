import React from 'react';

function ElectricalData({ inputArray }) {
    // Function to format the connection data
    const formatConnection = (connection) => {
        const connectionFields = ['type', 'size', 'units'];
        const formattedFields = connectionFields.map(field => {
            if (field === 'size' && Array.isArray(connection[field])) {
                const sizes = connection[field];
                if (sizes.length > 1) {
                    // Display as min(sizes) - max(sizes)
                    const sizeRange = `${Math.min(...sizes)} - ${Math.max(...sizes)}`;
                    return sizeRange;
                } else if (sizes.length === 1) {
                    // Display single size
                    return sizes[0];
                } else {
                    return ''; // No sizes to display
                }
            }
            return connection[field];
        }).filter(val => val).join(' ');

        return formattedFields;
    };

    if (inputArray && typeof inputArray === 'object' &&
        inputArray.hasOwnProperty('digitaloutputs') &&
        inputArray.hasOwnProperty('analogueoutputs') &&
        inputArray.hasOwnProperty('analoguecurrentoutput') &&
        inputArray.hasOwnProperty('analoguevoltageoutput')) {

        const electricalConnections = inputArray.electricalconnection || {};

        const connectionKeys = Object.keys(electricalConnections).slice(0, 2);
        const limitedConnections = connectionKeys.map(key => electricalConnections[key])
            .filter(connection => Object.values(connection).every(value => value !== null));

        return (
            <div className='module-container'>
                <div style={{borderBottom: '1px #d8dee4 solid'}}>Electrical Details</div>
                
                    <span className='module-span-1'>
                        <span className='module-span-2'>Outputs:</span> 
                        <span>{inputArray.digitaloutputs} D, {inputArray.analogueoutputs} A</span>
                    </span>
                    <span className='module-span-1'>
                        <span className='module-span-2'>Current Output:</span>
                        <span>{inputArray.analoguecurrentoutput}</span>
                    </span>
                    <span className='module-span-1'>
                        <span className='module-span-2'>Voltage Output:</span>
                        <span>{inputArray.analoguevoltageoutput}</span>
                    </span>
                    {limitedConnections.length > 0 && (
                        <>
                            {limitedConnections.map((connection, index) => (
                                <span key={`electrical-connection-${index}`} className='module-span-1'>
                                    <span className='module-span-2'>Connection {index + 1}:{" "}</span>
                                    <span>{formatConnection(connection)}</span>
                                </span>
                            ))}
                        </>
                    )}
               
            </div>
            
        );
    }

    return typeof inputArray === 'string' || typeof inputArray === 'number'
        ? <span>{inputArray}</span>
        : null;
}

export default ElectricalData;
