import React, { useEffect, useContext } from 'react'
import { useAuth } from '../AuthContext'
import CustomerInformationComponent from './customerInformationComponent'
import ShippingInformationComponent from './shippingInformationComponent';
import BillingInformationComponent from './billingInformationComponent';
import BreadCrumbComponent from './customerBreadCrumbsComponent';
import BreadCrumbButton from './breadCrumbButton'
import SideBarComponent from './sideBarComponent'
import ConfirmationComponent from './confirmationComponent';
import CompletionComponent from './completionComponent'
import testComponent from './testComponent.js'
import PaymentComponent from './paymentComponent.js'
import { Elements } from '@stripe/react-stripe-js';
import { useStripeContext } from './context/StripeContext';

import './css/checkoutComponent.css'
import './css/cartComponent.css'
import { BreadCrumbContext } from './context/BreadCrumbContext';
import { PurchaseOrderMandateProvider } from './context/PurchaseOrderMandateContext';

import { CheckoutContext } from './context/CheckoutContext';
import { useNavigate } from 'react-router-dom';

import useIdleActivity from './../useIdleActivity'; // Import the custom hook
import { StripeContextProvider } from './context/StripeContext.js';
import CheckoutForm from './CheckoutForm.js';

function CheckoutComponent() {
    const { currentStep } = useContext(BreadCrumbContext);
    const { setUser} = useAuth()
    const navigate = useNavigate()
    const { initiateCheckout } = useContext(CheckoutContext) 
    useEffect(() => {
        initiateCheckout()
    },[])

    const handleLogout = () => {
        setUser(null);
        navigate('/login')
        // Optionally, you can also remove cookies or perform other logout actions here
    };

    useIdleActivity(handleLogout);

    const {stripePromise, clientSecret, stripeOptions} = useStripeContext()

    
    return (
        <div style={{height: 'calc(100% - 75px)', overflow: 'auto'}}>
            <body className="CheckoutEntryPoint" >
            <div class="content" data-content aria-hidden="false">
                <div className="wrap"> 
                    
                    <PurchaseOrderMandateProvider>
                    
                    
                    <div className="main">
                        <div className="customer-information" style={{"width": "100%"}}>
                            <div className="checkout-breadcrumbs">
                                <BreadCrumbComponent/>
                            </div>
                            {currentStep === 1 && (
                                <div>
                                    <div className="customer-information">
                                        <CustomerInformationComponent/>
                                    </div>
                                    <div className="shipping-information">
                                        <ShippingInformationComponent/>
                                    </div>
                                </div>
                            )}
                            {currentStep === 2 && (
                                <div>
                                    <div className="billing-information">
                                        <BillingInformationComponent/>
                                    </div>
                                </div>
                            )}
                            {currentStep === 3 && (
                                <div>
                                    <div className="confirm-information">
                                        <ConfirmationComponent/>
                                        
                                    </div>
                                </div>
                            )}
                            {currentStep === 4 && (
                                <div>
                                    <div className="confirm-information">
                                        
                                        {stripePromise && clientSecret && (
                                            <Elements stripe={stripePromise} options={stripeOptions}>
                                                <CheckoutForm/>
                                            </Elements>
                                        )}
                                        
                                    </div>
                                </div>
                            )}
                            {currentStep === 5 && (
                                <div>
                                    <div className="completion">
                                        <CompletionComponent/>
                                    </div>
                                </div>
                            )}
                            <div>
                                <BreadCrumbButton/>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar" style={{height: 'calc(100vh - 75px)'}}>
                        <div className="cart">
                            <SideBarComponent/>
                        </div>
                    </div>
                    
                    </PurchaseOrderMandateProvider>
                    
                    
                </div>
            </div>
        </body>
        </div>
        
    )
}


export default CheckoutComponent