function OperatingConditions({ inputArray }) {
    // Check if inputArray is an object and has the expected structure
    if (inputArray && typeof inputArray === 'object' && 
        inputArray.hasOwnProperty('operatingtemperature') && 
        inputArray.hasOwnProperty('operatingpressure')) {
            const operatingTemperature = inputArray.operatingtemperature
            const operatingPressure = inputArray.operatingpressure
            const burstingpressure = inputArray.hasOwnProperty('burstingpressure') ? inputArray.burstingpressure : null;
            const protection = inputArray.hasOwnProperty('protection') ? inputArray.protection : null;
            const processmedium = inputArray.hasOwnProperty('processmedium') ? inputArray.processmedium : null;

        // Correctly format and return as a string
        return (
            <div className='module-container'>
                <div style={{borderBottom: '1px #d8dee4 solid'}}>Operating Conditions</div>
                <span className='module-span-1'>
                  <span className='module-span-2'>Temperature:</span>
                  <span>{`${operatingTemperature.min} - ${operatingTemperature.max} ${operatingTemperature.units}`}</span>
                </span>
                <span className='module-span-1'>
                  <span className='module-span-2'>Pressure:</span>
                  <span>{`${operatingPressure.min} - ${operatingPressure.max} ${operatingPressure.units}`}</span>
                </span>
                {burstingpressure && (
                  <span className='module-span-1'>
                    <span className='module-span-2'>Burst Pressure:</span>
                    <span>{burstingpressure.value} {burstingpressure.units}</span>
                  </span>
                )}
                {protection && protection.length > 0 && (
                  <span className='module-span-1'>
                    <span className='module-span-2'>Protection:</span>
                    <span>
                      {protection.length > 1
                        ? `IP ${Math.min(...protection)} - ${Math.max(...protection)}`
                        : `IP ${protection[0]}`}
                    </span>
                  </span>
                )}
                {processmedium && (
                  <span className='module-span-1'>
                    <span className='module-span-2'>Process Medium:</span>
                    <span>
                      {processmedium.liquid && processmedium.gas
                        ? 'Liquid, Gas'
                        : processmedium.liquid
                        ? 'Liquid'
                        : processmedium.gas
                        ? 'Gas'
                        : ''}
                    </span>
                  </span>
                )}
             
            </div>
        );
    }

    // If inputArray does not match the expected format,
    // Check if it's a simple type (string or number) and return it directly,
    // or return null if it's none of those.
    return typeof inputArray === 'string' || typeof inputArray === 'number' 
        ? <span>{inputArray}</span> 
        : null; 
}

export default OperatingConditions;

