import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthContext.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import './App.css';
import CustomerEquipmentComponent from './components/body/customerBody/CustomerEquipmentComponent.js';
import CustomerHeaderComponent from './components/header/customerHeader/HeaderComponent.js';
import SupplierHeaderComponent from './components/header/supplierHeader/SupplierHeaderComponent.js'
import { ScrollProvider } from './components/body/customerBody/scrollContext.js';
import CustomerNavigationComponent from './components/profile/customer/CustomerNavigationComponent.js';
import LandingPageComponent from './components/landingPage/LandingPageComponent.js';
import RouteWatcher from './components/RouteWatcher.js';
import CheckoutComponent from './components/checkout/checkoutComponent.js';
import { FormDataProvider } from './components/checkout/context/FormDataContext.js';
import { BreadCrumbProvider } from './components/checkout/context/BreadCrumbContext';
import LoginComponent from './components/loginComponent.js'
import OrdersComponent from './components/orders/OrdersComponent.js'
//import ZephyrSort from './components/zephyrSort/zephyrSortComponent.js';

import { TargetRouteProvider } from './components/TargetRoute';

import { WebSocketProvider } from './components/WebSocketContext';

import { EquipmentProvider } from './components/body/customerBody/CombinedContext.js';

import { OrdersProvider } from './components/orders/OrdersContext';
import { ShipmentDataProvider } from './components/checkout/context/ShipmentDataContext';

import { CheckoutProvider } from './components/checkout/context/CheckoutContext.js';

import { AlertProvider } from './components/body/customerBody/AlertContext.js'
import CustomAlert from './components/CustomAlertComponent'; 

import { ImageProvider } from './components/body/customerBody/ImageContext.js';

import { StripeContextProvider } from './components/checkout/context/StripeContext.js';


import { CheckoutImageProvider } from './components/checkout/context/CheckoutImageContext.js'
import SupplierEquipmentBody from './components/body/supplierBody/supplierEquipment/SupplierEntrypoint.js';
import { ChipProvider } from './components/body/supplierBody/supplierEquipment/ChipComponents/context/ChipContext.js';
import { ChipFilterProvider } from './components/body/supplierBody/supplierEquipment/ChipComponents/context/ChipFilterContext.tsx';

function App() {
  const { user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {setIsModalOpen(true);};
  const closeModal = () => {setIsModalOpen(false);};
  const [customerEquipmentComponent, setCustomerEquipmentComponent] = useState(true);
  const [customerProfileComponent, setCustomerProfileComponent] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  return (
      <TargetRouteProvider>
      <Router>
      <AuthProvider>
      <RouteWatcher />
        <Routes>
          <Route path="/" element={!user ? (
            <div>
              <LandingPageComponent/>
            </div>
          ) : null} />
          <Route path="/customer/*" element={
            <AlertProvider>
              <CustomAlert />
            <EquipmentProvider>
            <ImageProvider>
            <CheckoutImageProvider>
            <OrdersProvider>
            <WebSocketProvider>
            <FormDataProvider>
            <ShipmentDataProvider>
              <Routes>
                <Route path="" element={
                  <ProtectedRoute allowedUserTypes={['customer']}>
                    <ChipFilterProvider>
                    <ChipProvider>
                      <CustomerHeaderComponent/>
                      <SupplierEquipmentBody/>
                    </ChipProvider>
                    </ChipFilterProvider>







                  
                  </ProtectedRoute>
                } />
                <Route path="profile" element={
                  <ProtectedRoute allowedUserTypes={['customer']}>
                    <div style={{height: '100vh', overflow: 'hidden'}}>
                      <CustomerHeaderComponent 
                        openModal={openModal}
                        setCustomerEquipmentComponent={setCustomerEquipmentComponent}
                        setCustomerProfileComponent={setCustomerProfileComponent}
                      />
                      <CustomerNavigationComponent />
                    </div>
                  </ProtectedRoute>
                } />
                <Route path="checkout" element={
                  <ProtectedRoute allowedUserTypes={['customer']}>
                    <CheckoutProvider>
                    <BreadCrumbProvider>
                    <div style={{height: '100vh', overflow: 'hidden'}}>
                      <CustomerHeaderComponent 
                        openModal={openModal}
                        
                        setCustomerEquipmentComponent={setCustomerEquipmentComponent}
                        setCustomerProfileComponent={setCustomerProfileComponent}
                      />
                      <StripeContextProvider>
                      <CheckoutComponent />
                      </StripeContextProvider>
                    </div>
                      
                    </BreadCrumbProvider>
                    </CheckoutProvider>
                  </ProtectedRoute>
                } />
                <Route path="orders" element={
                  <ProtectedRoute allowedUserTypes={['customer']}>
                    <CustomerHeaderComponent 
                      openModal={openModal}
                      
                      setCustomerEquipmentComponent={setCustomerEquipmentComponent}
                      setCustomerProfileComponent={setCustomerProfileComponent}
                    />
                    <OrdersComponent />
                    
                  </ProtectedRoute>
                } />
                
              </Routes>
              </ShipmentDataProvider>
              </FormDataProvider>
              
              </WebSocketProvider>
              </OrdersProvider>
              </CheckoutImageProvider>
              </ImageProvider>
              </EquipmentProvider>
              </AlertProvider>
          } />
          <Route path="/supplier/*" element={
            <AlertProvider>
              <CustomAlert/>
              <Routes>
                <Route path="" element={
                  <ProtectedRoute allowedUserTypes={['supplier']}>
                    <ChipFilterProvider>
                    <ChipProvider>
                      <SupplierHeaderComponent/>
                      <SupplierEquipmentBody/>
                    </ChipProvider>
                    </ChipFilterProvider>
                  </ProtectedRoute>
                }>
                </Route>      
              </Routes>
            </AlertProvider>
          }/>
          <Route path="/login" element={
            <AlertProvider>
              <CustomAlert/>
              <LoginComponent/>
            </AlertProvider>
          }/>
        </Routes>
    </AuthProvider>
    </Router>
    </TargetRouteProvider>
  );
}

export default App;





//<div style={{ minWidth: "100%", overflow: "hidden" }}>
//                    <CustomerHeaderComponent/>
//                      {customerEquipmentComponent && (
//                        <ScrollProvider>
//                          <CustomerEquipmentComponent 
//                            //hiddenColumns={[]}
//                            refreshData={refreshData}
//                            newRefreshData={setRefreshData}
//                          />
//                        </ScrollProvider>
//                      )}
//                    {customerProfileComponent && (
//                      <CustomerNavigationComponent />
//                    )}
//                    
//                  </div>