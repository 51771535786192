import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';

type InputWithUnitSelectorProps = {
  value: string | number;
  setValue: (size: string | number) => void;
  unit: string;
  setUnit: (unit: string) => void;
  label: string;
  selectors: string;
};

const DNToNPS: Record<number, string> = {
  6: '1/8', 8: '1/4', 10: '3/8', 15: '1/2', 20: '3/4', 25: '1',
  32: '1 1/4', 40: '1 1/2', 50: '2', 65: '2 1/2', 80: '3', 90: '3 1/2',
  100: '4', 115: '4 1/2', 125: '5', 150: '6', 200: '8', 250: '10',
  300: '12', 350: '14', 400: '16', 450: '18', 500: '20', 600: '24',
  700: '28', 800: '32', 900: '36', 1000: '40', 1050: '42', 1100: '44',
  1200: '48', 1300: '52', 1400: '56', 1500: '60', 1600: '64', 1700: '68',
  1800: '72', 1900: '76', 2000: '80', 2200: '88', 2400: '96', 2600: '104',
  2800: '112', 3000: '120', 3200: '128', 3400: '134', 3600: '142', 3800: '150'
};

const NPSToDN: Record<string, number> = Object.entries(DNToNPS).reduce((acc, [dn, nps]) => {
  acc[nps] = Number(dn);
  return acc;
}, {} as Record<string, number>);

const convertSizeToUnit = (size: string | number, targetUnit: string, previousUnit: string): string => {
  let convertedSize: string | number = size;

  if (previousUnit === 'DN' && targetUnit === 'NPS') {
    convertedSize = DNToNPS[Number(size)] || '';
  } else if (previousUnit === 'NPS' && targetUnit === 'DN') {
    convertedSize = NPSToDN[size.toString()] || '';
  } else if (previousUnit === targetUnit) {
    return size.toString(); // No conversion needed
  }

  return convertedSize.toString();
};

const convertPressureToUnit = (pressure: number, targetUnit: string, previousUnit: string): string => {
  if (isNaN(pressure)) return '';

  console.log(pressure)
  
  //converting from bar to something else initially doesnt work
  //same with temperature

  
  

  //if (!previousUnit) {
  //  previousUnit = 'bar'; // Default previous unit
  //}

  console.log(targetUnit, previousUnit) // previous unit is initially ''

  let convertedPressure: number;

  // Convert from previousUnit to targetUnit
  if (previousUnit === 'bar' && targetUnit === 'PSI') {
    convertedPressure = pressure * 14.503774; // Bar to PSI
  } else if (previousUnit === 'bar' && targetUnit === 'Atm') {
    convertedPressure = pressure / 1.01325; // Bar to Atm
  } else if (previousUnit === 'bar' && targetUnit === 'kPa') {
    convertedPressure = pressure * 100; // Bar to kPa
  } else if (previousUnit === 'PSI' && targetUnit === 'bar') {
    convertedPressure = pressure / 14.503774; // PSI to Bar
  } else if (previousUnit === 'PSI' && targetUnit === 'Atm') {
    convertedPressure = pressure / 14.696; // PSI to Atm
  } else if (previousUnit === 'PSI' && targetUnit === 'kPa') {
    convertedPressure = pressure * 6.894757; // PSI to kPa
  } else if (previousUnit === 'Atm' && targetUnit === 'bar') {
    convertedPressure = pressure * 1.01325; // Atm to Bar
  } else if (previousUnit === 'Atm' && targetUnit === 'PSI') {
    convertedPressure = pressure * 14.696; // Atm to PSI
  } else if (previousUnit === 'Atm' && targetUnit === 'kPa') {
    convertedPressure = pressure * 101.325; // Atm to kPa
  } else if (previousUnit === 'kPa' && targetUnit === 'bar') {
    convertedPressure = pressure / 100; // kPa to Bar
  } else if (previousUnit === 'kPa' && targetUnit === 'PSI') {
    convertedPressure = pressure / 6.894757; // kPa to PSI
  } else if (previousUnit === 'kPa' && targetUnit === 'Atm') {
    convertedPressure = pressure / 101.325; // kPa to Atm
  } else if (previousUnit === targetUnit) {
    convertedPressure = pressure; // No conversion needed
  } else {
    return pressure.toString(); // Unknown unit, return as is
  }
  
  return convertedPressure.toString();
};

const convertTemperatureToUnit = (temp: number, targetUnit: string, previousUnit: string): string => {
  if (isNaN(temp)) return '';
  let convertedTemp: number;
  // Convert from previousUnit to targetUnit
  if (previousUnit === 'C' && targetUnit === 'F') {
    convertedTemp = (temp * 9 / 5) + 32; // Celsius to Fahrenheit
  } else if (previousUnit === 'C' && targetUnit === 'K') {
    convertedTemp = temp + 273.15; // Celsius to Kelvin
  } else if (previousUnit === 'F' && targetUnit === 'C') {
    convertedTemp = (temp - 32) * 5 / 9; // Fahrenheit to Celsius
  } else if (previousUnit === 'F' && targetUnit === 'K') {
    convertedTemp = ((temp - 32) * 5 / 9) + 273.15; // Fahrenheit to Kelvin
  } else if (previousUnit === 'K' && targetUnit === 'C') {
    convertedTemp = temp - 273.15; // Kelvin to Celsius
  } else if (previousUnit === 'K' && targetUnit === 'F') {
    convertedTemp = ((temp - 273.15) * 9 / 5) + 32; // Kelvin to Fahrenheit
  } else if (previousUnit === targetUnit) {
    convertedTemp = temp; // No conversion needed
  } else {
    return temp.toString(); // Unknown unit, return as is
  }
  return convertedTemp.toString();
};

const InputWithUnitSelector: React.FC<InputWithUnitSelectorProps> = ({ value, setValue, unit, setUnit, label, selectors }) => {
  const [displayValue, setDisplayValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (selectors === 'processconnections') {
      console.log("inside the processconnections selector")
      const initialValue = value !== undefined ? value.toString() : '';
      const displayVl = initialValue ? convertSizeToUnit(initialValue, unit, '') : '';
      setDisplayValue(displayVl);
      if (displayVl === '') {
        setIsFocused(false)
      }
    } else if (selectors === 'pressure') {
      const initialPressure = value !== undefined ? parseFloat(value.toString()) : parseFloat('');
      const displayPress = !isNaN(initialPressure) ? convertPressureToUnit(initialPressure, unit, '') : '';
      if (displayPress === '') {
        setIsFocused(false)
      }
      setDisplayValue(displayPress);
    } else if (selectors === 'temperature') {
      const initialTemp = parseFloat(value.toString());
      const displayTemp = !isNaN(initialTemp) ? convertTemperatureToUnit(initialTemp, unit, '') : '';
      if (displayTemp === '') {
        setIsFocused(false)
      }
      setDisplayValue(displayTemp);
    }
  }, [value, unit, selectors]);

  const handleUnitChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const newUnit = e.target.value;
    console.log('unit?', unit)
    let prevUnit = unit
    setUnit(newUnit);
    if (selectors === 'processconnections') {
      if (displayValue) {
        const convertedValue = convertSizeToUnit(displayValue, newUnit, prevUnit);
        setDisplayValue(convertedValue);
        setValue(convertedValue);
      }
    } else if (selectors === 'pressure') {
      const numericDisplayPressure = parseFloat(displayValue);
      if (!prevUnit) {
        prevUnit = 'bar'
      }
      if (!isNaN(numericDisplayPressure)) {
        const convertedPressure = convertPressureToUnit(numericDisplayPressure, newUnit, prevUnit);
        setDisplayValue(convertedPressure);
        setValue(convertedPressure)
      }
    } else if (selectors === 'temperature') {
      const numericDisplayTemperature = parseFloat(displayValue);
      if (!prevUnit) {
        prevUnit = 'C'
      }
      if (!isNaN(numericDisplayTemperature)) {
        const convertedTemperature = convertTemperatureToUnit(numericDisplayTemperature, newUnit, prevUnit);
        setDisplayValue(convertedTemperature);
        setValue(convertedTemperature)
      }
    }
  }, [displayValue, selectors, setUnit, unit]);

  const handleSizeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(e.target.value);
    setValue(e.target.value)
  }, []);

  const handleSizeBlur = useCallback(() => {
    if (selectors === 'processconnections') {
      if (displayValue.trim() === '') {
        setValue('');
        setIsFocused(false); 
      } else {
        const convertedValue = convertSizeToUnit(displayValue, unit, "");
        
        setValue(convertedValue);
        if (!convertedValue) {
          setIsFocused(false);
        }
      }
    } else if (selectors === 'pressure') {
      if (displayValue.trim() === '') {
        setValue('');
        setIsFocused(false);
      } else {
        const numericDisplayPressure = parseFloat(displayValue);
        if (!isNaN(numericDisplayPressure)) {
          console.log("test here???")
          const convertedPressure = convertPressureToUnit(numericDisplayPressure, unit, "");
          setValue(convertedPressure);
        }
      }
    } else if (selectors === 'temperature') {
      if (displayValue.trim() === '') {
        setValue('');
        setIsFocused(false);
      } else {
        const numericDisplayTemperature = parseFloat(displayValue);
        if (!isNaN(numericDisplayTemperature)) {
          const convertedTemperature = convertTemperatureToUnit(numericDisplayTemperature, unit, "");
          setValue(convertedTemperature);
        }
      }
    }
  }, [displayValue, selectors, setValue, unit]);

  const inputStyle: React.CSSProperties = {
    width: '100%',
    padding: isFocused || value ? '16px 5px 3px 5px' : '8px 5px',
    fontSize: '12px',
    transition: 'padding-top 0.2s ease, padding-bottom 0.2s ease',
    border: 'none',
    outline: 'none',
    borderRadius: '4px'
  };

  const labelStyle: React.CSSProperties = {
    position: 'absolute',
    top: isFocused || value ? '0px' : '8px',
    left: '5px',
    fontSize: isFocused || value ? '10px' : '12px',
    color: isFocused || value ? '#000' : '#aaa',
    pointerEvents: 'none',
    transition: 'all 0.2s ease'
  };

  const formGroupStyle: React.CSSProperties = {
    position: 'relative',
  };

  const formGroupWrapper: React.CSSProperties = {
    border: 'solid 1px #ccc',
    borderRadius: '4px',
    paddingBottom: isFocused || value ? '0px' : '3.5px',
    transition: 'all 0.2s ease',
    marginBottom: '5px',
    width: '100%'
  };

  return (
    <div style={{ display: 'flex', minHeight: '40.5px' }}>
      <div style={formGroupWrapper}>
        <div style={formGroupStyle}>
          <input
            style={inputStyle}
            type="text"
            id={label}
            name={label}
            value={displayValue}
            onChange={handleSizeChange}
            onBlur={handleSizeBlur}
            onFocus={() => setIsFocused(true)}
          />
          <label htmlFor={label} style={labelStyle}>{label}</label>
        </div>
      </div>
      <div>
        {selectors === 'processconnections' && (
          <select value={unit} onChange={handleUnitChange} style={{ marginLeft: '5px', padding: '8.5px 5px', fontSize: '12px', border: '1px solid #ccc', borderRadius: '4px', outline: 'none', minWidth: '56px' }}>
            <option value="DN">DN(mm)</option>
            <option value="NPS">NPS(in.)</option>
          </select>
        )}
        {selectors === 'pressure' && (
          <select value={unit} onChange={handleUnitChange} style={{ marginLeft: '5px', padding: '8.5px 5px', fontSize: '12px', border: '1px solid #ccc', borderRadius: '4px', outline: 'none', minWidth: '56px' }}>
            <option value="bar">Bar</option>
            <option value="PSI">PSI</option>
            <option value="Atm">Atm</option>
            <option value="kPa">kPa</option>
          </select>
        )}
        {selectors === 'temperature' && (
          <select value={unit} onChange={handleUnitChange} style={{ marginLeft: '5px', padding: '9px 5px', fontSize: '12px', border: '1px solid #ccc', borderRadius: '4px', outline: 'none', minWidth: '56px' }}>
            <option value="C">°C</option>
            <option value="F">°F</option>
            <option value="K">K</option>
          </select>
        )}
      </div>
    </div>
  );
};

export default InputWithUnitSelector;
