import React, { useEffect, useState, useRef, useContext } from 'react'
import './css/loginComponent.css'
import GoogleLoginButton from './landingPage/GoogleLoginButton'
import OutlookLoginButton from './landingPage/OutlookLoginButton'
import { PulseLoader } from 'react-spinners';
import { useCookies } from 'react-cookie';
import InputBoxComponent from './InputBoxComponent.tsx';
import { useAuth } from './AuthContext.js';
import { useNavigate } from 'react-router-dom';
import ZephyrInnovaitionsLogo2 from './../assets/Zephyr Innovations Logo 2 (4).svg';

import { AlertContext } from "./body/customerBody/AlertContext"

const apiUrl = process.env.REACT_APP_API_URL;

function LoginComponent() {
    const navigate = useNavigate();
    const { user, setUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [acceptsTerms, setAcceptsTerms] = useState(false);
    const [acceptsPrivacy, setAcceptsPrivacy] = useState(false);
    const [customerAquisitionSuccess, setCustomerAquisitionSuccess] = useState(false);
    const [cookies, setCookies] = useCookies(['cart_cookie', 'csrf_token']);
    const { showAlertMessage } = useContext(AlertContext); 
    const [formData, setFormData] = useState({
        given_name: '',
        family_name: '',
        email: '',
        contact_number: '',
        company: '',
        abn: '',
        accounts_email: '',
        previous_billing: '',
        previous_delivery: ''
    });

    const [formData2, setFormData2] = useState({
        given_name: '',
        family_name: '',
        email: '',
        contact_number: '',
        company: '',
    })

    useEffect(() => {
        if (user && user.email) {
            setFormData2(prevFormData2 => ({
                ...prevFormData2,
                email: user.email
            }));
        }
    }, [user]);
    

    useEffect(() => {
        const handleBeforeUnload = () => {
            // Function to clear cookies
            const clearCookie = (name) => {
                document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            };
            // Clear the specific cookies
            clearCookie('user');
            clearCookie('csrf_token');
            clearCookie('transaction_id');
    
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        if (!acceptsTerms) {
            alert("You must accept the terms and conditions to proceed.");
            return;
        }

        if (!validateForm()) {
            return; // Stop the form submission if validation fails
        }

        // Send the payload to your Flask 'SignUp' route
        try {
            const response = await fetch(`${apiUrl}/api/userSignUp`, { // Make sure this URL is correct for your setup
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookies.csrf_token,
                },
                body: JSON.stringify({
                    userDetails: formData,
                    email: user.email,
                    userType: user.userType,
                    acceptsTerms: acceptsTerms
                }),
                credentials: 'include',
            });
            const data = await response.json();
            console.log(data)
            if (data.success) {
                setUser(prevUser => {
                    const { signUp, ...rest } = prevUser; // Destructure to remove 'signUp'
                    const updatedUser = {
                        ...rest,
                        userType: data.user_type,
                        company: data.company,
                        given_name: data.given_name,
                        family_name: data.family_name,
                        contact_number: data.contact_number,
                        abn: data.abn,
                        accounts_email: data.accounts_email,
                        previous_delivery: data.previous_delivery,
                        previous_billing: data.previous_billing
                    };
                    return updatedUser;
                });
                if (data.user_type === 'customer') {
                    navigate('/customer');
                }
                if (data.user_type === 'supplier') {
                    navigate('/supplier')
                }
            } else {
                console.log("TEST")
                console.error('Signup failed', data.message);
                alert("signup failed", data.message);
            }
        } catch (error) {
            console.error('Network error:', error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
    };

    const validateForm = () => {
        let errors = [];

        // Basic validations
        if (!/^[A-Za-z]+$/.test(formData.given_name)) {
            errors.push("First name can only contain letters.");
        }
        if (!/^[A-Za-z]+$/.test(formData.family_name)) {
            errors.push("Last name can only contain letters.");
        }
        // Validate Company Name for letters, spaces, and numbers
        if (!/^[A-Za-z0-9 ]+$/.test(formData.company)) {
            errors.push("Invalid company name");
        }
        // Validate phone number for the Australian format starting with 04 followed by 8 digits
        if (!/^04\d{8}$/.test(formData.contact_number)) {
            errors.push("Phone number must be a valid Australian number starting with 04 followed by 8 digits.");
        }

        if (!/^[1-9]\d{10}$/.test(formData.abn)) {
            errors.push("ABN must be an 11-digit number and cannot start with 0.");
        }

        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.accounts_email)) {
            errors.push("Invalid accounts email address.");
        }

        // Displaying all errors in a single alert
        if (errors.length > 0) {
            errors.forEach((error, index) => {
                setTimeout(() => {
                    showAlertMessage('warning', error);
                }, index * 10); // Slight delay to create distinct alerts
            });
            setIsLoading(false)
            return false;
        }

        return true;
    };

    const handleSubmit2 = async (e) => {
        console.log("submitted 2")
        setIsLoading(true)
        e.preventDefault();
        if (!acceptsTerms) {
            alert("You must accept the terms and conditions to proceed.");
            return;
        }

        if (!isForm2Valid()) {
            return; // Stop the form submission if validation fails
        }

        try {
            const response = await fetch(`${apiUrl}/api/userAquisition`, { // Make sure this URL is correct for your setup
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookies.csrf_token,
                },
                body: JSON.stringify({
                    userDetails: formData2
                }),
                credentials: 'include',
            });
            const data = await response.json();
            console.log(data)
            if (data.success) {
                console.log("success!!!!!")
                await new Promise((resolve) => setTimeout(resolve, 1000))
                setCustomerAquisitionSuccess(true)
                setUser(null);
                setTimeout(() => {
                    
                    setCustomerAquisitionSuccess(false);
                    console.log("State set back to false after 10 seconds.");
                }, 10000);
            } else {
                console.log("TEST")
                alert("User aquisition failed", data.message);
            }
        } catch (error) {
            console.error('Network error:', error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
    }

    const isForm2Valid = () => {
        console.log(formData2)
        console.log(user)
        return Object.values(formData2).every(value => value !== null && value !== undefined && value !== '');
    };

    return(
        <div className="page-container">
            <div className="main-content2">
                <div className="col-sm-5 col-md-4" id="left-panel">
                    <div className="left-panel-container">
                        <div className="login-container" style={{maxHeight: '100%', overflowY: 'auto', overflowX: 'hidden'}}>
                            <img src={ZephyrInnovaitionsLogo2} alt="Zephyr Innovations Logo" style={{ marginRight: 'auto', height: '100%', width: '300px' }} />
                            <p style={{fontSize: '14px'}}>To continue to Zephyr Innovations, please login or sign up with the options below!</p>
                            <GoogleLoginButton/>
                            <OutlookLoginButton/>  
                        </div>
                        {user && (user.signUp === true) && (
                            <div className= "sign-up-container visible">
                            <p style={{fontSize: '14px'}}>To sign up as a {user.userType}, please fill out your details in the form below!</p>
                            <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                                <div className="form-group-wrapper">
                                    <div style={{"width":"100%"}}>
                                        <InputBoxComponent
                                          string={user && user.email ? user.email : ''}
                                          setString={(e) => {setFormData({ ...formData, email: e })}}
                                          label={'Email Address'}
                                          //defaultValue={user.email}
                                          disabled={true}
                                          regexType='email'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="location-container" style={{"display":"flex", "gap": "10px"}}>
                                <div className="form-group-wrapper">
                                    <div style={{"minWidth": "100%"}}>
                                        <InputBoxComponent
                                          string={user && user.given_name ? user.given_name : formData.given_name}
                                          setString={(e) => {setFormData({ ...formData, given_name: e })}}
                                          label={'First Name'}
                                          regexType='string'
                                        />
                                    </div>
                                </div>
                                <div className="form-group-wrapper">
                                    <div style={{"minWidth": "100%"}}>
                                        <InputBoxComponent
                                          string={formData.family_name}
                                          setString={(e) => {setFormData({ ...formData, family_name: e })}}
                                          label={'Last Name'}
                                          regexType='string'
                                        />
                                    </div>
                                </div>   
                            </div>
                            <div className="form-group-wrapper">
                                        <div style={{"width":"100%"}}>
                                            <InputBoxComponent
                                              string={formData.company}
                                              setString={(e) => {setFormData({ ...formData, company: e })}}
                                              label={'Company'}
                                              regexType='string'
                                            />
                                            
                                        </div>
                                        </div>
                                        <div className="form-group-wrapper">
                                        <div style={{"width":"100%"}}>
                                            <InputBoxComponent
                                              string={formData.abn}
                                              setString={(e) => {setFormData({ ...formData, abn: e })}}
                                              label={'ABN'}
                                              regexType='number'
                                            />
                                        </div>
                                    
                                </div>
                            
                            
                                <div className="form-group-wrapper">
                                    <div style={{display: 'flex', gap: '10px'}}>
                                        <div  style={{width: '100px'}}>
                                            <InputBoxComponent
                                              string={'+61'}
                                              setString={''}
                                              label={'Country Code'}
                                              regexType='string'
                                              disabled={true}
                                            />
                                        </div>
                                        <div  style={{flexGrow: 1}}>
                                            <InputBoxComponent
                                              string={formData.contact_number}
                                              setString={(e) => {setFormData({ ...formData, contact_number: e })}}
                                              label={'Contact Number'}
                                              regexType='number'
                                            />
                                        </div>
                                    </div>
                                </div>
                            
                            <div className="location-container">
                                <div className="form-group-wrapper">
                                    <div  style={{"minWidth": "100%"}}>
                                        <InputBoxComponent
                                          string={formData.accounts_email}
                                          setString={(e) => {setFormData({ ...formData, accounts_email: e })}}
                                          label={'Accounts Email'}
                                          regexType='email'
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', border: 'solid 1px #ccc', marginTop: '5px', borderRadius: '4px' }}>
                                <div style={{padding: "10px 8px"}}>
                                    <input
                                        id="terms-and-conditions"
                                        type="radio"
                                        name="termsandconditions"
                                        className='hidden-radio'
                                        value="Terms and Conditions"
                                        checked={acceptsTerms}
                                        onChange={(e) => setAcceptsTerms(e.target.checked)}
                                        disabled={isLoading}
                                    />
                                    <label htmlFor="terms-and-conditions" className="input-radio-label">
                                        <span className="input-radio" style={{fontFamily: 'inherit'}}></span>
                                        <p style={{margin: 0, fontSize: '14px'}}>I accept the <a href={`${apiUrl}/api/download/termsandconditions`} download style={{color: '#339AF0', fontWeight: 'bold', textDecoration: 'none'}}> terms and conditions</a></p>
                                    </label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', border: 'solid 1px #ccc', marginTop: '5px', borderRadius: '4px' }}>
                                <div style={{padding: "10px 8px"}}>
                                    <input
                                        id="privacy-policy"
                                        type="radio"
                                        name="privacypolicy"
                                        className='hidden-radio'
                                        value="Privacy Policy"
                                        checked={acceptsPrivacy}
                                        onChange={(e) => setAcceptsPrivacy(e.target.checked)}
                                        disabled={isLoading}
                                    />
                                    <label htmlFor="privacy-policy" className="input-radio-label">
                                        <span className="input-radio" style={{fontFamily: 'inherit'}}></span>
                                        <p style={{margin: 0, fontSize: '14px'}}>I accept the <a href={`${apiUrl}/api/download/privacypolicy`} download style={{color: '#339AF0', fontWeight: 'bold', textDecoration: 'none'}}> privacy policy</a></p>
                                    </label>
                                </div>
                            </div>
                            
                    <button className='part-type-button2' style={{marginTop: '10px'}} type="submit" disabled={!acceptsTerms || !acceptsPrivacy} onClick={handleSubmit}>
                        {isLoading ? (
                            <PulseLoader size={5} color={"#FFFFFF"} />
                        ) : (
                            "Signup"
                        )}
                    </button>
                        </div>
                        )}
                        {user && (user.customerAquisition === true) && !customerAquisitionSuccess && (
                            <div className= "sign-up-container visible">
                            <p style={{fontSize: '14px'}}>Welcome! To register with Zephyr Innovations please fill out your details in the form below!</p>
                            <div className="location-container" style={{"display":"flex","width":"100%","gap":"10px"}}>
                                <div className="form-group-wrapper">
                                    <div style={{"width":"100%"}}>
                                        <InputBoxComponent
                                          string={user && user.email ? user.email : ''}
                                          setString={(e) => {setFormData2({ ...formData2, email: user.email })}}
                                          label={'Email Address'}
                                          defaultValue={user.email}
                                          disabled={true}
                                          regexType='email'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="location-container" style={{"display":"flex", "gap": "10px"}}>
                                <div className="form-group-wrapper">
                                    <div style={{"minWidth": "100%"}}>
                                        <InputBoxComponent
                                          string={formData2.given_name}
                                          setString={(e) => {setFormData2({ ...formData2, given_name: e })}}
                                          label={'First Name'}
                                          regexType='string'
                                        />
                                    </div>
                                </div>
                                <div className="form-group-wrapper">
                                    <div style={{"minWidth": "100%"}}>
                                        <InputBoxComponent
                                          string={formData2.family_name}
                                          setString={(e) => {setFormData2({ ...formData2, family_name: e })}}
                                          label={'Last Name'}
                                          regexType='string'
                                        />
                                    </div>
                                </div>   
                            </div>
                            <div className="form-group-wrapper">
                                        <div style={{"width":"100%"}}>
                                            <InputBoxComponent
                                              string={formData2.company}
                                              setString={(e) => {setFormData2({ ...formData2, company: e })}}
                                              label={'Company'}
                                              regexType='string'
                                            />
                                            
                                        </div>
                            
                            
                                        </div>
                                        <div className="form-group-wrapper">
                                    <div style={{display: 'flex', gap: '10px'}}>
                                        <div  style={{width: '100px'}}>
                                            <InputBoxComponent
                                              string={'+61'}
                                              setString={''}
                                              label={'Country Code'}
                                              regexType='string'
                                              disabled={true}
                                            />
                                        </div>
                                        <div  style={{flexGrow: 1}}>
                                            <InputBoxComponent
                                              string={formData2.contact_number}
                                              setString={(e) => {setFormData2({ ...formData2, contact_number: e })}}
                                              label={'Contact Number'}
                                              regexType='number'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', border: 'solid 1px #ccc', marginTop: '5px', borderRadius: '4px' }}>
                                <div style={{padding: "10px 8px"}}>
                                    <input
                                        id="terms-and-conditions"
                                        type="radio"
                                        name="termsandconditions"
                                        className='hidden-radio'
                                        value="Terms and Conditions"
                                        checked={acceptsTerms}
                                        onChange={(e) => setAcceptsTerms(e.target.checked)}
                                        disabled={isLoading}
                                    />
                                    <label htmlFor="terms-and-conditions" className="input-radio-label">
                                        <span className="input-radio" style={{fontFamily: 'inherit'}}></span>
                                        <p style={{margin: 0, fontSize: '14px'}}>I accept the <a href={`${apiUrl}/api/download/termsandconditions`} download style={{color: '#339AF0', fontWeight: 'bold', textDecoration: 'none'}}> terms and conditions</a></p>
                                    </label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', border: 'solid 1px #ccc', marginTop: '5px', borderRadius: '4px' }}>
                                <div style={{padding: "10px 8px"}}>
                                    <input
                                        id="privacy-policy"
                                        type="radio"
                                        name="privacypolicy"
                                        className='hidden-radio'
                                        value="Privacy Policy"
                                        checked={acceptsPrivacy}
                                        onChange={(e) => setAcceptsPrivacy(e.target.checked)}
                                        disabled={isLoading}
                                    />
                                    <label htmlFor="privacy-policy" className="input-radio-label">
                                        <span className="input-radio" style={{fontFamily: 'inherit'}}></span>
                                        <p style={{margin: 0, fontSize: '14px'}}>I accept the <a href={`${apiUrl}/api/download/privacypolicy`} download style={{color: '#339AF0', fontWeight: 'bold', textDecoration: 'none'}}> privacy policy</a></p>
                                    </label>
                                </div>
                            </div>
                            <button className='part-type-button2' style={{marginTop: '10px'}} type="submit" onClick={handleSubmit2}  disabled={!isForm2Valid() || !acceptsTerms || !acceptsPrivacy}>
                                {isLoading ? (
                                    <PulseLoader size={5} color={"#FFFFFF"} />
                                ) : (
                                    "Submit details"
                                )}
                            </button>
                            </div>
                        )}
                        {customerAquisitionSuccess && (
                             <div className= "sign-up-container visible">
                                <p style={{fontSize: '14px'}}>Thank you for submitting your information! A representative shall be in contact with you soon</p>
                            </div>
                        )}
                    </div>
                </div>
                <div id="right-panel" className="col-sm-7 col-md-8 darkbg">
                    <div className="right-panel-image-slant"></div>
                    <div className="right-panel-container"></div>
                </div>
            </div>            
        </div>
    )
}


export default LoginComponent


