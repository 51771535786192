import React from 'react';

// Define the props interface
interface NoContentProps {
  label: string; // The message to display
  height?: string; // Optional height (default to '100px')
  width?: string; // Optional width (default to '200px')
  margin?: string;
}

// The NoContent component
const NoContent: React.FC<NoContentProps> = ({ label, height = '100%', width = '100%', margin = '0px' }) => {
  return (
    <div
      style={{
        border: '2px dashed #d3d3d3', // Light gray dashed border
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: height,
        width: width,
        borderRadius: '10px', // Rounded corners
        color: '#8c8c8c', // Text color
        fontSize: '16px', // Font size
        margin: margin
      }}
    >
      {label}
    </div>
  );
};

export default NoContent;
