import React from 'react';

const ConfigurationSelections = ({ equipmentData, selectedRowIndex, selectedButton, selectedOption, handleButtonClick, isNarrow, showSelections }) => {
  return (
    <>
     <div 
      className={`configuration-selections`}
      style={{
        width: isNarrow ? '100%' : '',
        paddingRight: isNarrow ? '16px' : ''
      }}
    >
      <div className="custom-header-1">Selections</div>
      {equipmentData[selectedRowIndex].configurableoptions.map((item, index) => (
        <div key={item.designation}>
          <button
            className={`part-type-button config-option-button ${selectedButton === index ? 'selected' : ''} flex justify-between items-center`}
            style={
              selectedOption[item.designation]
                ? { borderLeft: '4px solid #339AF0', padding: '5px 5px 5px 1px', minWidth: '130px' }
                : { borderLeft: '1px solid #ccc', padding: '5px', minWidth: '130px' }
            }
            onClick={() => handleButtonClick(item.designation, item.option.designation, index)}
          >
            <span style={{ display: 'flex', flexDirection: 'column', fontSize: '12px', width: '100%' }}>
              <span style={{ display: 'flex', flexDirection: 'row' }}>
                <span>{item.designation}</span>
                <span style={{ marginLeft: 'auto' }} className="monostyle-text">
                  {selectedOption[item.designation]}
                </span>
              </span>
              <span>{item.description}</span>
            </span>
          </button>
        </div>
        
      ))}
      </div>
    </>
  );
};

export default ConfigurationSelections;


