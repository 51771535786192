import React, { useEffect, useState, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useLocation  } from 'react-router-dom';
import { useAuth } from '../../AuthContext.js'
import './css/HeaderComponent.css';

import { ReactComponent as Logo } from './../../../assets/Logo 1.svg'; // Assuming you have the SVG saved as a React component
import { ReactComponent as MenuIcon } from './../../../assets/burger-menu-svg.svg'; // Save menu icon SVG as a React component
import { ReactComponent as CloseIcon } from './../../../assets/x-close-delete-svg.svg'; // Save close icon SVG as a React component

import ZephyrInnovaitionsLogo2 from './../../../assets/Zephyr Innovations Logo 2 (4).svg';
import { faSolid, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTargetRoute } from './../../TargetRoute';

import { CheckoutContext } from './../../checkout/context/CheckoutContext';

//<FontAwesomeIcon className='fa-icon' icon={faCheckToSlot} />

function HeaderComponent() {
  const { user, setUser } = useAuth();
  const navigate = useNavigate()
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  

  function handleNavClick(navType) {
    let targetPath = '';
    if (navType) {
        targetPath = `${navType}`;
    }

    if (location.pathname !== targetPath) {
      navigate(targetPath);
    } 
  }

  function handleMobileNavClick(navType) {
    let targetPath = '';
    if (navType) {
        targetPath = `${navType}`;
    }
    if (location.pathname !== targetPath) {
        navigate(targetPath);
    } else {
      toggleMenu()
    }
  }

  function handleLogout() {
    setUser(null)
  }

  function handleLogin() {
    navigate('/login')
  }

  return (
    <div className="tailwind">
        <div className="bg-blue-500">
          <nav className="relative px-4 py-4 flex justify-between items-center bg-white" style={{maxHeight: '62px'}}>
            <a style={{cursor: "pointer"}} onClick={() => handleNavClick("/customer")} className="text-3xl font-bold leading-none">
              <img src={ZephyrInnovaitionsLogo2} alt="Zephyr Innovations Logo" style={{ marginRight: 'auto', height: '100%', width: '250px' }}/>
              
            </a>
            <div className="lg:hidden">
              <button className="navbar-burger flex items-center text-blue-600 p-3 bg-transparent border-none cursor-pointer" onClick={toggleMenu}>
                <FontAwesomeIcon className="block h-4 w-4 fill-current" icon={faBars}/>
              </button>
            </div>
            <ul className={`tw-ul-style absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:items-center lg:w-auto lg:space-x-6 ${isMenuOpen ? 'hidden' : 'hidden'}`}>
              <li className='tw-li-style'><a className={`text-sm cursor-pointer ${location.pathname === "/customer/profile" ? 'text-blue-600 font-bold' : 'text-gray-400 hover:text-gray-500'}`}         onClick={() => handleNavClick("/customer/profile")}>Profile</a></li>
              <li className='tw-li-style'><a className={`text-sm cursor-pointer ${location.pathname === "/customer" ? 'text-blue-600 font-bold' : 'text-gray-400 hover:text-gray-500'}`}                 onClick={() => handleNavClick("/customer")}>Equipment</a></li>
              
              <li className='tw-li-style'><a className={`text-sm cursor-pointer ${location.pathname === "/customer/checkout" ? 'text-blue-600 font-bold' : 'text-gray-400 hover:text-gray-500'}`}        onClick={() => handleNavClick("/customer/checkout")}>Checkout</a></li>
              <li className='tw-li-style'><a className={`text-sm cursor-pointer ${location.pathname === "/customer/orders" ? 'text-blue-600 font-bold' : 'text-gray-400 hover:text-gray-500'}`}          onClick={() => handleNavClick("/customer/orders")}>Orders</a></li>
            </ul>
            <div className='hidden lg:flex'>
              {user ?
                <div>
                  <a className="py-2 px-6 bg-gray-50 hover:bg-gray-100 text-sm text-gray-900 font-bold rounded-xl transition duration-200 cursor-pointer" onClick={handleLogout}>Log Out</a>
                </div> 
                : 
                <div>
                  <a className="py-2 px-6 bg-blue-500 hover:bg-blue-600 text-sm text-white font-bold rounded-xl transition duration-200 cursor-pointer" onClick={handleLogin}>Log In</a>
                </div> }                
            </div>
          </nav>
          <div className={`navbar-menu relative z-50 ${isMenuOpen ? '' : 'hidden'}`}>
            <div className=" navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" onClick={toggleMenu}></div>
            <nav className=" fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
              <div className="flex items-center mb-8">
                <a href="#" className="mr-auto text-3xl font-bold leading-none">
                  
                  <img src={ZephyrInnovaitionsLogo2} alt="Zephyr Innovations Logo" style={{ marginRight: 'auto', height: '100%', width: '250px' }}/>
                </a>
                <button className="navbar-close bg-transparent border-none cursor-pointer" onClick={toggleMenu}>
                  <FontAwesomeIcon className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}/>
                </button>
              </div>
              <div>
                <ul className='tw-ul-style'>
                  <li className="mb-1 tw-li-style">
                    <a className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded cursor-pointer" onClick={() => handleMobileNavClick("/customer/profile")}>Profile</a>
                  </li>
                  <li className="mb-1 tw-li-style">
                    <a className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded cursor-pointer" onClick={() => handleMobileNavClick("/customer")}>Equipment</a>
                  </li>
                  
                  <li className="mb-1 tw-li-style">
                    <a className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded cursor-pointer" onClick={() => handleMobileNavClick("/customer/checkout")}>Checkout</a>
                  </li>
                  <li className="mb-1 tw-li-style">
                    <a className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded cursor-pointer" onClick={() => handleMobileNavClick("/customer/orders")}>Orders</a>
                  </li>
                </ul>
              </div>
              <div className="mt-auto">
                    <div className={`pt-6 ${!user ? '' : 'hidden'}`}>
                        <a className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-blue-600 hover:bg-blue-700 rounded-xl cursor-pointer" onClick={handleLogin} >Log In</a>
                    </div>
                    <div className={`pt-6 ${user ? '' : 'hidden'}`}>
                        <a className="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold bg-gray-50 hover:bg-gray-100 rounded-xl cursor-pointer" onClick={handleLogout}>Log Out</a>
                    </div>
                
                <p className="my-4 text-xs text-center text-gray-400">
                  <span>Copyright © 2021</span>
                </p>
              </div>
            </nav>
          </div>
        </div>
    </div>
  );
}

export default HeaderComponent;


