import React, { useContext } from 'react'
import { BreadCrumbContext } from './context/BreadCrumbContext';
import './css/breadCrumbComponent.css'

function BreadCrumbComponent() {
    const { currentStep } = useContext(BreadCrumbContext);

    const steps = [
        { id: 'first-step', name: 'Shipping' },
        { id: 'second-step', name: 'Billing' },
        { id: 'third-step', name: 'Confirm' },
        { id: 'fourth-step', name: 'Payment' } // If this step has no name, consider if it needs to be displayed or have a conditional rendering.
    ];

    return (
        <div className="container-nav">
            <ul className="steps-progress-bar">
                {steps.map((step, index) => (
                    <React.Fragment key={step.id}>
                        <li id={step.id} className={`step ${currentStep > index ? "active" : ""}`}>
                            <div>
                                <p className="step-name" style={{ color: currentStep === index + 1 ? "#339af0" : "initial", fontWeight: "bold"}}>{step.name}</p>
                            </div>
                        </li>
                        {index < steps.length - 1 && (
                            <li className="step-chevron">
                                <i className="fas fa-chevron-right">
                                
                                </i>
                            </li>
                        )}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
}

export default BreadCrumbComponent