import React, { useState, useRef, useEffect, RefObject } from 'react';
///import './TabNavigation.css'; // Import CSS file for styling

//const tabs = ['Details', 'Documents'] as const; // Define tab labels as a constant

interface TabNavigationProps {
    tabs: string[]; // Accept tabs as a prop
    onTabClick?: (index: number) => void; // Optional callback function for tab click
    selectedRowIndex?: number
  }

const TabNavigation: React.FC<TabNavigationProps> = ({tabs, onTabClick, selectedRowIndex}) => {
  const [activeTab, setActiveTab] = useState<number>(0); // Index of the active tab
  const [underlineStyle, setUnderlineStyle] = useState<React.CSSProperties>({}); // Style object for the underline
  const tabsRef = useRef<(HTMLButtonElement | null)[]>([]); // References to the buttons

  useEffect(() => {
    console.log("component reset?")
    setActiveTab(0)
  },[selectedRowIndex])

  useEffect(() => {
    // Set initial underline style and update on activeTab change
    console.log(activeTab, "ACTIVE TAB")
    const currentTabElement = tabsRef.current[activeTab];
    if (currentTabElement) {
      updateUnderlineStyle(currentTabElement);
    }
  }, [activeTab]);
  

  const updateUnderlineStyle = (element: HTMLButtonElement | null) => {
    if (element) {
      const buttonRect = element.getBoundingClientRect();
      const containerRect = element.parentElement!.getBoundingClientRect();

      setUnderlineStyle({
        width: `${buttonRect.width}px`,
        left: `${buttonRect.left - containerRect.left}px`,
      });
    }
  };

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    if (onTabClick) onTabClick(index);
  };


  const tabContainerStyle: React.CSSProperties = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '10px',
    gap: '25px',
    color: '#596171'
  };
  
  const tabButtonStyle: React.CSSProperties = {
    background: 'none',
    border: 'none',
    padding: '4px 0px',
    fontSize: '12px',
    cursor: 'pointer',
    position: 'relative',
    color: '#333',
  };

  const activeTabButtonStyle: React.CSSProperties = {
    ...tabButtonStyle, // Base styles
    color: '#339AF0', 
  };

  const underlineBaseStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: 0,
    height: '2px',
    backgroundColor: '#339AF0',
    transition: 'all 0.25s ease',
  };
  

  return (
    <div style={tabContainerStyle}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          ref={(el) => (tabsRef.current[index] = el)}
          style={index === activeTab ? activeTabButtonStyle : tabButtonStyle}
          onClick={() => handleTabClick(index)}
        >
          {tab}
        </button>
      ))}
      <div className="underline" style={{ ...underlineBaseStyle, ...underlineStyle }}></div>
    </div>
  );
};

export default TabNavigation;
