import React, { createContext, useContext, useState, useEffect } from 'react';

const ShipmentDataContext = createContext()

export const useShipmentData = () => useContext(ShipmentDataContext)

export const ShipmentDataProvider = ({ children }) => {
    const [shippingData, setShipmentData] = useState({
        delivery_method: 'Express shipping',
        shipping_type: 'Single shipments'
    })

    const updateShippingData = (newData) => {
        setShipmentData(prev => ({ ...prev, ...newData}));
    }

    return (
        <ShipmentDataContext.Provider value={{shippingData, updateShippingData}}>
            {children}
        </ShipmentDataContext.Provider>
    )
}