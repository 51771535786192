import React, { useContext, useEffect } from 'react';
import './CustomAlert.css';
import { AlertContext } from './body/customerBody/AlertContext'; // Adjust the path as necessary

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faCheck } from '@fortawesome/free-solid-svg-icons';

import { useLocation } from 'react-router-dom';

const CustomAlert = () => {
    const { alerts, removeAlert, clearAlerts  } = useContext(AlertContext);
    const location = useLocation();

    useEffect(() => {
        clearAlerts();
    }, [location.pathname]);

    useEffect(() => {
        if (alerts.length > 0) {
            const timer = setTimeout(() => {
                removeAlert(alerts[0].id);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [alerts, removeAlert]);

    return (
        <div className="alert-container">
            {alerts.map((alert, index) => { 
                console.log(alert.type)
                return (
                    <div 
                        key={alert.id} 
                        className={`custom-alert custom-alert-${alert.type} fade-in-out`} 
                        style={{ 
                            zIndex: 9999, 
                            position: 'fixed', 
                            bottom: `${20 + (alerts.length - 1 - index) * 80}px`, 
                            right: '30px' 
                        }}
                        onAnimationEnd={() => removeAlert(alert.id)}
                    >
                        <div style={{marginRight: '5px'}}>
                        {alert.type === 'success' ? (
                            <FontAwesomeIcon icon={faCheck} color={'#155724'} />
                        ) : (
                            <FontAwesomeIcon icon={faTriangleExclamation} color={'#ed6c02'} />
                        )}
                        
                        </div>

                        <span style={{alignItems: 'center'}}>{alert.message}</span>
                        <button style={{alignSelf: 'center'}} onClick={() => removeAlert(alert.id)} className={`custom-alert-close-${alert.type}`}>&times;</button>
                    </div>
                )
            })}
        </div>
    );
};

export default CustomAlert;
